<template>
  <div class="pagination-web">
    <button class="pagination-btn btn-prev">&lt;</button>
    <ul>
      <li class="more">&middot;&middot;&middot;</li>
      <li v-for="item in pageList" :key="item" @click="onClickPageNumber(item)">
        {{ item }}
      </li>
      <li class="more">&middot;&middot;&middot;</li>
    </ul>
    <button class="pagination-btn btn-next">&gt;</button>
  </div>
</template>

<script>
export default {
  title: "PaginationWeb",
  props: {
    total: Number
  },
  data() {
    return {
      pageList: [],
      active: 5
    };
  },
  methods: {
    onClickPageNumber() {}
  },
  created() {
    const pageNumber = Math.ceil(this.total / 10);
    if (pageNumber === 0) {
      this.pageList = [];
    } else if (pageNumber <= 5 && pageNumber > 0) {
      this.pageList = new Array(5).map((item, key) => key);
    } else if (pageNumber > 5) {
      this.pageList = new Array(pageNumber).map((item, key) => key);
    }
  }
};
</script>

<style scoped lang="scss">
.pagination-web {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB, sans-serif;
  font-weight: 700;
}

ul {
  display: inline-block;
}

.pagination-btn,
li {
  height: 30px;
  display: inline-block;

  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
  padding: 0 4px;
  font-size: 13px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  user-select: none;
  list-style: none;
}

.pagination-btn {
  border: none;
  outline: 0;
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB, sans-serif;
  font-weight: 700;
}

.pagination-btn::after {
  content: "";
  width: 5px;
  height: 5px;
}
.pagination-btn:hover {
  color: #409eff;
}
li:hover {
  color: #409eff;
}
</style>