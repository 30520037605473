import login from "@/pages/login/login.vue";
import Home from "@/pages/system/Home.vue";
import Write from "@/pages/system/write-canvas";

export default {
	pages: [

		{
			path: "/patient",
			meta: {
				title: "客户",
				icon: "&#xe64d;",
				p: [1, 2, 5, 6, 10, 11, 12, 13],
				code: 101,
				default: 1
			},
			// subMenu: ["patientList",'patientBase','initialCondition','medicalSciences','newMenus','patientBase','patientBase','patientBase','patientBase','patientBase','patientBase','patientBase','patientBase'],
			// name: "newNenu",
			// name: "newNenus2",
			children: [
				{
					path: "list",
					name: "patientList",
					component: () => import("./pages/patient/patient-list.vue"),
					meta: { title: "客户列表", code: 20101 }
				},
				{
					path: ":id/:account",
					name: "patientBase",
					component: () => import("./pages/patient/patient-base.vue"),
					meta: { title: "基本信息", code: 20102 },
					props: true,
				},
				{
					path: ":id/:account/initialCondition",
					name: "initialCondition",
					component: () => import("./pages/patient/initial-condition.vue"),
					meta: { title: "初始病情", code: 20110 },
					props: true,
				},
				{
					path: ":id/:account/medical-sciences",
					name: "medicalSciences",
					component: () => import("./pages/patient/medical-sciences/index.vue"),
					meta: { title: "首发基金", code: 20119 },
					props: true
				},
				{
					path: "/healthManage",
					name: "mangeHealth",
					// component: () => import("./pages/patient/patient-health-check.vue"),
					meta: { title: "健康评估", code: 20121 },
					props: true,
					subMenu: ["patientHeathNew", "newHeathReport"],
					children: [
						{
							path: ":id/:account/patientHeathNew",
							name: "patientHeathNew",
							component: () => import("./pages/patient/newHealthLu2.vue"),
							meta: { title: "健康评估", code: 20121 },
							props: true
						},
						{
							path: ":id/:account/case/newHeathReport",
							name: "newHeathReport",
							component: () => import("./pages/patient/new-health-report-list-lu.vue"),
							meta: { title: "健康档案", code: 20123 },
							props: true
						},
					]
				},
				{
					path: "/healthManage",
					name: "zztMange",
					// component: () => import("./pages/patient/patient-health-check.vue"),
					meta: { title: "健康管理", code: 20114 },
					props: true,
					subMenu: ["diagnoseZzt", "healthRecord"],
					children: [
						{
							path: ":id/:account/diagnoseNew",
							name: "diagnoseZzt",
							component: () => import("./pages/patient/patientNew.vue"), //正中堂
							meta: { title: "健康管理", code: 20114 },
							props: true
						},
						{
							path: ":id/:account/case/record",
							name: "healthRecord",
							component: () => import("./pages/patient/patient-record-list.vue"), //正中堂
							meta: { title: "健康档案", code: 20116 },
							props: true
						}
					]
				},

				{
					path: "/diagnoseJf",
					name: "patientDiagnoseJingFang",
					meta: { title: "经方诊疗", code: 20120 },
					props: true,
					subMenu: ["jfDiagnose", "jfRecord", "patienTraditionalJf", "traditionRecord", "westTreat"],
					children: [
						{
							path: ":id/:account/case/diagnoseJf",
							name: "jfDiagnose",
							component: () => import("./pages/patient/patientJingFang.vue"),
							meta: { title: "经方诊疗", code: 20120 },
							props: {
								type: 1 //1经方 2传统经方
							},
						},
						{
							path: ":id/:account/case/jfrecord",
							name: "jfRecord",
							component: () => import("./pages/patient/patient-jfrecord-list.vue"),
							meta: { title: "经方档案", code: 20120 },
							props: true
						},
						{
							path: ":id/:account/traditionalJf",
							name: "patienTraditionalJf",
							component: () => import("./pages/patient/patientJingFang.vue"),
							// meta: { title: "辨证开方", code: 20120 },
							meta: { title: "问诊辨证", code: 20122 },
							// props: true
							props: {
								type: 2 //1经方 2传统经方
							}
						},
						{
							path: ":id/:account/case/tradition",
							name: "traditionRecord",
							component: () => import("./pages/patient/patient-tra-record.vue"),
							meta: { title: "辨证档案", code: 20122 },
							// meta: { title: "辨证档案", code: 20120 },
							props: true
						},

					]
				},
				{
					path: "/proveRecipe",
					name: "newMenu",
					// component: () => import("./pages/patient/son-pages/test.vue"),
					meta: { title: "验方诊疗", code: 20103 },
					subMenu: ["patientDiagnose", "patientCaseList", "feedBack", "patientDataList", "dataAnalysis", "patientBloodTests", "bloodAnalysis", "patientBloodAnalyse"],
					children: [
						{
							path: ":id/:account/diagnose",
							name: "patientDiagnose",
							component: () => import("./pages/patient/patient.vue"),
							meta: { title: "验方诊疗", code: 20103 },
							props: true
						},
						{
							path: ":id/:account/case/list",
							name: "patientCaseList",
							component: () => import("./pages/patient/patient-case-list.vue"),
							meta: { title: "验方档案", code: 20104 },
							props: true
						},
						{
							path: ":id/:account/feedback",
							name: "feedBack",
							component: () => import("./pages/patient/patient-feedback.vue"),
							meta: { title: "疗效评估", code: 20108 },
							props: true
						},
						{
							path: ":id/:account/info/patientDataList",
							name: "patientDataList",
							component: () => import("./pages/patient/patient-info-list.vue"),
							meta: { title: "血压数据", code: 20111 },
							props: true
						},
						{
							path: ":id/:account/data/analysis",
							name: "dataAnalysis",
							component: () => import("./pages/patient/data-analysis.vue"),
							meta: { title: "血压图表", code: 20106 },
							props: true
						},
						{
							path: ":id/:account/blood/tests",
							name: "patientBloodTests",
							component: () => import("./pages/patient/patient-blood-tests.vue"),
							meta: { title: "验血数据", code: 20105 },
							props: true
						},
						{
							path: ":id/:account/blood/analysis",
							name: "bloodAnalysis",
							component: () => import("./pages/patient/blood-analysis.vue"),
							meta: { title: "验血图表", code: 20107 },
							props: true
						},
						{
							path: ":id/:account/info/patientBloodAnalyse",
							name: "patientBloodAnalyse",
							component: () => import("./pages/patient/patient-blood-analyse.vue"),
							meta: { title: "血压分析", code: 20113 },
							props: true
						},
					]
				},

			]
		},

		{
			path: "/doctor",
			meta: { title: "医生", icon: "&#xe651;", code: 102, default: 1, },
			children: [
				{
					path: "doctorlist",
					name: "doctorList",
					component: () => import("./pages/doctor/doctor-list.vue"),
					meta: { title: "医生列表", code: 20201 }
				},
				{
					path: ":id/base",
					name: "doctorInfo",
					component: () => import("@/pages/doctor/doctor-base.vue"),
					meta: { title: "基本信息", code: 20203 },
					props: true
				},
				{
					path: ":id/rec",
					name: "doctorRec",
					component: () => import("@/pages/doctor/doctor-rec.vue"),
					meta: { title: "推荐统计", code: 20203 },
					props: true
				},
			]
		},
		{
			path: "/outpatient",
			meta: {
				title: "门诊",
				icon: "&#xe617;",
				code: 103,
				default: 2,
			},
			children: [
				{
					path: "list",
					name: "outpatientList",
					component: () => import("./pages/outpatient/outpatient-list.vue"),
					meta: { title: "门诊列表", code: 20301 }
				},
				{
					path: ":id",
					name: "outDashboard",
					component: () => import("@/pages/charts/data-charts"),
					meta: { title: "数据统计", code: 20302 },
					props: {
						type: 3
					}
				},
				{
					path: ":id/outpatientBaseInfo",
					name: "outpatientBaseInfo",
					component: () => import("./pages/outpatient/outpatient-base.vue"),
					meta: { title: "基本信息", code: 20303 },
					props: true
				},
				{
					path: ":id/evaluateList",
					name: "evaluateList",
					component: () => import("./pages/outpatient/evaluateList.vue"),
					meta: { title: "疗效评估", code: 20304 },
					props: true
				},
				{
					path: ":id/hascreat",
					name: "hasCreatOrder",
					component: () => import("@/pages/assistant/hasCreatOrder.vue"),
					meta: { title: "已发煎药", code: 20306 },
					props: true
				},
			]
		},
		{
			path: "/company",
			meta: { title: "公司", icon: "&#xe61f;", code: 104, default: 0 },
			children: [
				{
					path: "list",
					name: "companyList",
					component: () => import("./pages/company/company-list.vue"),
					meta: { title: "公司列表", code: 20401 }
				},
				{
					path: ":id",
					name: "comDashboard",
					// component: () => import("@/pages/dashboard/dashboard.vue"),
					component: () => import("@/pages/charts/data-charts"),
					meta: { title: "数据统计", code: 20402 },
					props: {
						type: 2
					}
				},
				{
					path: ":id/companyBaseInfo",
					name: "companyBaseInfo",
					component: () => import("@/pages/company/company-base.vue"),
					meta: { title: "基本信息", code: 20403 },
					props: true
				}
			]
		},
		{
			path: "/sys",
			meta: { title: "总部", icon: "&#xe80f;", p: [1, 5, 10], code: 105, default: 0 },
			children: [
				{
					path: "",
					name: "sysDashboard",
					component: () => import("@/pages/charts/data-charts"),
					meta: { title: "数据统计", code: 20501 },
					props: {
						type: 1
					}
				},
			]
		},
		{
			path: "/basedata",
			meta: { title: "数据", icon: "&#xe616;", p: [1, 5, 10], code: 107, default: 0 },
			children: [
				{
					path: "/base",
					name: "medicinal",
					// component: () => import("./pages/s-base-data/medicinal.vue"),
					meta: { title: "通用资料", code: 20701 },
					subMenu: ["medicinal", "medicinalRule", "chinaMedicine", "dietTherapyMan", "acupointNew", "questionAnswer", "constitution", "tongueManage", "medicinalMaterials", "prescription"],
					children: [
						{
							path: "medicinalBase",
							name: "medicinal",
							component: () => import("./pages/s-base-data/medicinal.vue"),
							meta: { title: "药材", code: 20703 }
						},
						{
							path: "medicinalRule",
							name: "medicinalRule",
							component: () => import("./pages/s-base-data/medicinal-rule.vue"),
							meta: { title: "中药反畏", code: 20703 }
						},
						{
							path: "chinaMedicine",
							name: "chinaMedicine",
							component: () => import("@/pages/s-smart-data/china-medicine.vue"),
							meta: { title: "中成药管理", code: 20807 }
						},
						{
							path: "dietTherapyMan",
							name: "dietTherapyMan",
							component: () => import("@/pages/s-smart-data/diet-therapy.vue"),
							meta: { title: "食疗管理", code: 20807 }
						},
						{
							path: "acupointNew",
							name: "acupointNew",
							component: () => import("@/pages/s-smart-data/acupoint-new.vue"),
							meta: { title: "穴位管理", code: 20807 }
						},
						{
							path: "questionAnswer",
							name: "questionAnswer",
							component: () => import("@/pages/s-smart-data/question-answer.vue"),
							meta: { title: "问答管理", code: 20807 }
						},
						{
							path: "constitution",
							name: "constitution",
							component: () => import("@/pages/s-smart-data/constitution.vue"),
							meta: { title: "体质管理", code: 20807 }
						},
						{
							path: "tongueManage",
							name: "tongueManage",
							component: () => import("@/pages/s-smart-data/tongue.vue"),
							meta: { title: "舌象管理", code: 20807 }
						},
						{
							path: "/medicinalMaterial",
							name: "medicinalMaterials",
							component: () => import("@/pages/mini-app/medicinal-materials"),
							meta: { title: "基础药材", code: 20807 }
						},
						{
							path: "prescription",
							name: "prescription",
							component: () => import("@/pages/s-smart-data/prescription.vue"),
							meta: { title: "经方管理", code: 20807 }
						},
					]
				},
				{
					path: "/wuYf",
					name: "acupoint",
					// component: () => import("./pages/s-base-data/medicinal.vue"),
					meta: { title: "吴老验方", code: 20701 },
					subMenu: ["acupoint", "scrapping", "tongue", "sysBlood", "steamRecipe", "smartSymptom", "smartSteam", "smartScrapping", "smartAcupuncture", "smartHealth", "bloodWarn", 'symptomMain'],
					children: [
						{
							path: "acupoint",
							name: "acupoint",
							component: () => import("./pages/s-base-data/acupoint.vue"),
							meta: { title: "穴位", code: 20702 }
						},
						{
							path: "scrapping",
							name: "scrapping",
							component: () => import("./pages/s-base-data/scrapping.vue"),
							meta: { title: "刮痧", code: 20703 }
						},
						{
							path: "tongue",
							name: "tongue",
							component: () => import("@/pages/s-base-data/tongue.vue"),
							meta: { title: "舌像", code: 20706 }
						},
						{
							path: "sysBlood",
							name: "sysBlood",
							component: () => import("@/pages/s-base-data/sys-blood.vue"),
							meta: { title: "验血", code: 20707 }
						},
						{
							path: "steamRecipe",
							name: "steamRecipe",
							component: () => import("./pages/s-base-data/steam-recipe.vue"),
							meta: { title: "蒸汽药包", code: 20711 }
						},
						{
							path: "smart-symptom",
							name: "smartSymptom",
							component: () => import("./pages/s-base-data/smart-symptom.vue"),
							meta: { title: "智能症状", code: 20712 }
						},
						{
							path: "smartSteam",
							name: "smartSteam",
							component: () => import("./pages/s-base-data/smart-steam.vue"),
							meta: { title: "智能蒸汽", code: 20713 }
						},
						{
							path: "smartScrapping",
							name: "smartScrapping",
							component: () => import("@/pages/s-base-data/smart-scrapping.vue"),
							meta: { title: "智能刮痧", code: 20714 }
						},
						{
							path: "smartAcupuncture",
							name: "smartAcupuncture",
							component: () => import("@/pages/s-base-data/smart-acupuncture.vue"),
							meta: { title: "智能针灸", code: 20715 }
						},
						{
							path: "smartHealth",
							name: "smartHealth",
							component: () => import("@/pages/s-base-data/smart-health.vue"),
							meta: { title: "饮食建议", code: 20716 }
						},
						{
							path: "bloodWarn",
							name: "bloodWarn",
							component: () => import("@/pages/s-base-data/Warning.vue"),
							meta: { title: "警告阈值", code: 20717 }
						},
						{
							path: "direction",
							name: "direction",
							component: () => import("@/pages/s-smart-data/direction.vue"),
							meta: { title: "治疗方向", code: 20801 }
						},
						{
							path: "symptom-main",
							name: "symptomMain",
							component: () => import("./pages/s-smart-data/symptom-main.vue"),
							meta: { title: "主治病症", code: 20802 }
						},
						{
							path: "analyzeProvedRecipe",
							name: "analyzeProvedRecipe",
							component: () =>
								import("@/pages/s-smart-data/analyzeProvedRecipe.vue"),
							meta: { title: "验方管理", code: 20805 }
						},
						{
							path: "prescribe",
							name: "prescribe",
							component: () => import("@/pages/s-smart-data/prescribe.vue"),
							meta: { title: "出方大小", code: 20805 }
						},
						{
							path: "patient-info",
							name: "patientInfo",
							component: () => import("@/pages/s-smart-data/patientInfo.vue"),
							meta: { title: "望闻问", code: 20806 }
						},
						{
							path: "medication-type",
							name: "medicationType",
							component: () => import("@/pages/s-smart-data/MedicationType.vue"),
							meta: { title: "吃药类型", code: 20806 }
						},
						{
							path: "symptom-optional",
							name: "symptom-optional",
							component: () => import("@/pages/s-smart-data/symptomOptional.vue"),
							meta: { title: "自选症状", code: 20806 }
						},
						{
							path: "ingredients",
							name: "ingredients",
							component: () => import("@/pages/s-smart-data/ingredients.vue"),
							meta: { title: "旧食材", code: 20806 }
						},
						{
							path: "dietProvedRecipe",
							name: "dietProvedRecipe",
							component: () => import("@/pages/s-smart-data/dietProvedRecipe.vue"),
							meta: { title: "旧食疗方", code: 20806 }
						},
						{
							path: "teabagManger",
							name: "teabagManger",
							component: () => import("@/pages/s-smart-data/tea-bag.vue"),
							meta: { title: "茶包管理", code: 20807 }
						},
						{
							path: "bathBagManage",
							name: "bathBagManage",
							component: () => import("@/pages/s-smart-data/bath-bag.vue"),
							meta: { title: "熏蒸泡脚包", code: 20807 }
						}
					]
				},

				{
					path: "/jfzl",
					name: "symptom",
					// component: () => import("./pages/s-base-data/medicinal.vue"),
					meta: { title: "经方诊疗", code: 20701 },
					subMenu: ["symptom", "symptomList", "symptomUnion", "bodySystem", "bodySysQuestion", "traditionalCNres", "regulation", "diseaseBodySys"],
					children: [
						{
							path: "symptom",
							name: "symptom",
							component: () => import("./pages/s-base-data/symptom.vue"),
							meta: { title: "预测症状", code: 20709 }
						},
						{
							path: "symptomList",
							name: "symptomList",
							component: () => import("@/pages/s-smart-data/symptom-list.vue"),
							meta: { title: "智能病症", code: 20807 }
						},
						{
							path: "symptomUnion",
							name: "symptomUnion",
							component: () => import("@/pages/s-smart-data/symptom-union.vue"),
							meta: { title: "病症合集", code: 20807 }
						},
						{
							path: "bodySystem",
							name: "bodySystem",
							component: () => import("@/pages/s-smart-data/body-sys.vue"),
							meta: { title: "身体系统", code: 20807 }
						},
						{
							path: "bodySysQuestion",
							name: "bodySysQuestion",
							component: () => import("@/pages/s-smart-data/body-sys-question.vue"),
							meta: { title: "身体系统问题", code: 20807 }
						},
						{
							path: "traditionalCNres",
							name: "traditionalCNres",
							component: () =>
								import("@/pages/s-smart-data/traditional-medicine.vue"),
							meta: { title: "中医诊断结果", code: 20807 }
						},
						{
							path: "regulation",
							name: "regulation",
							component: () => import("@/pages/s-smart-data/regulation.vue"),
							meta: { title: "诊断规则", code: 20807 }
						},
						{
							path: "diseaseBodySys",
							name: "diseaseBodySys",
							component: () => import("@/pages/s-smart-data/diseaseBodySys.vue"),
							meta: { title: "病症身体系统", code: 20807 }
						},
					]
				},

				{
					path: "traditionalJf",
					name: "traditionalJf",
					component: () => import("@/pages/s-smart-data/traditional-jingfang"),
					meta: { title: "辨证诊疗", code: 20807 }
				},
				// {
				// 	path: "/xyzb",
				// 	name: "westBaseData",
				// 	// component: () => import("./pages/s-base-data/medicinal.vue"),
				// 	meta: { title: "西医指标", code: 20807 },
				// 	subMenu: ["westBaseData", "westData"],
				// 	children: [
				// 		{
				// 			path: "westBaseData",
				// 			name: "westBaseData",
				// 			component: () => import("@/pages/s-smart-data/west-base-data"),
				// 			meta: { title: "样本类别", code: 20807 }
				// 		},
				// 		{
				// 			path: "westData",
				// 			name: "westData",
				// 			component: () => import("@/pages/s-smart-data/west-data"),
				// 			meta: { title: "西医指标", code: 20807 }
				// 		},
				// 	]
				// },
				{
					path: "/others",
					name: "products",
					// component: () => import("./pages/s-base-data/medicinal.vue"),
					meta: { title: "其他", code: 20807 },
					subMenu: ["products", "diseaseProduct", "bloodCompute"],
					children: [
						{
							path: "products",
							name: "products",
							component: () => import("@/pages/s-smart-data/product.vue"),
							meta: { title: "产品管理", code: 20807 }
						},
						{
							path: "diseaseProduct",
							name: "diseaseProduct",
							component: () => import("@/pages/s-smart-data/disease-product.vue"),
							meta: { title: "病症产品", code: 20807 }
						},

						// {
						// 	path: "bloodCompute",
						// 	name: "bloodCompute",
						// 	component: () => import("@/pages/s-smart-data/blood-compute.vue"),
						// 	meta: { title: "血压计算", code: 20807 }
						// },
					]
				}
			]
		},

		{
			path: "/pharmacy",
			meta: { title: "药房", icon: "&#xe611;", p: [8], code: 109, default: 0 },
			children: [
				{
					path: "waitingList",
					name: "waitingList",
					component: () => import("@/pages/pharmacy/waitingList.vue"),
					meta: { title: "待抓药方", code: 10901 },
					props: { tranStatus: 2 }
				},
				{
					path: "prepare",
					name: "prepare",
					component: () => import("@/pages/pharmacy/waitingList.vue"),
					meta: { title: "正在抓药", code: 10901 },
					props: { tranStatus: 12 }
				},

				{
					path: "isStart",
					name: "isStart",
					component: () => import("@/pages/pharmacy/waitingList.vue"),
					meta: { title: "正在煎药", code: 10902 },
					props: { tranStatus: 13 }
				},
				// {
				//   path: "allTisaneList",
				//   name: "allTisaneList",
				//   component: () => import("@/pages/pharmacy/allTisaneList.vue"),
				//   meta: { title: "全部订单" }
				// },
				{
					path: "finishList",
					name: "finishList",
					component: () => import("@/pages/pharmacy/finishList.vue"),
					meta: { title: "出货记录", code: 10903 }
				}
				// {
				//   path: "",
				//   name: "pProvedRecipe",
				//   component: () => import("@/pages/pharmacy/pProvedRecipe.vue"),
				//   meta: { title: "验方" }
				// }
			]
		},
		{
			path: "/finance",
			meta: { title: "财务", icon: "&#xe8ae;", p: [14], code: 110, default: 0 },
			children: [
				{
					path: "",
					name: "financeList",
					component: () => import("@/pages/finance/financeList.vue"),
					meta: { title: "订单信息", code: 11001 }
				},
				// {
				// 	path: "/home/finance",
				// 	name: "homeFinance",
				// 	component: () => import("@/pages/finance/HomeFinance.vue"),
				// 	meta: { title: "订单合计", code: 11003 }
				// },

				{
					path: "provedRecipes",
					name: "provedRecipes",
					component: () => import("@/pages/finance/provedRecipes.vue"),
					meta: { title: "验方查询", code: 11002 }
				},
				{
					path: "medicinal-man",
					name: "medicinalMans",
					component: () => import("./pages/company/medicinal.vue"),
					meta: { title: "药材价格", code: 11002 },
					props: { id: 1 }
				},
				// {
				// 	path: "outPatientFinance",
				// 	name: "outPatientFinance",
				// 	component: () => import("@/pages/finance/outPatientFinance.vue"),
				// 	meta: { title: "数据统计", code: 11002 }
				// },
				{
					path: "month-statistics",
					name: "monthStatistic",
					component: () => import("@/pages/finance/monthStatistics.vue"),
					meta: { title: "药材统计", code: 11002 }
				},
				{
					path: "/shoppingMall/marketPoints",
					name: "shoppingMallPoints",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "积分设置", code: 11002 },
					props: {
						type: 7
					}
				},
				// {
				//   path: "orderList",
				//   name: "orderList",
				//   component: () => import("@/pages/finance/orderList.vue"),
				//   meta: { title: "huiyuan订单列表", code: 11002 }
				// },
			]
		},
		{
			path: "/website",
			meta: { title: "网站", icon: "&#xe65e;", p: [14], code: 112, default: 0 },
			children: [
				{
					path: "",
					name: "list",
					component: () => import("@/pages/website/website"),
					meta: { title: "新闻管理", code: 21201 }
				},
				{
					path: "/website/doctorList",
					name: "doctor",
					component: () => import("@/pages/website/doctorList"),
					meta: { title: "医生管理", code: 21201 }
				},
				{
					path: "/website/research",
					name: "research",
					component: () => import("@/pages/website/research"),
					meta: { title: "临床研究", code: 21201 }
				},
				{
					path: "/website/oldAge",
					name: "keepHealth",
					component: () => import("@/pages/website/keepHealth"),
					meta: { title: "养老专栏", code: 21201 },
					props: { type: "oldHealth" }
				},
				{
					path: "/website/healthMan",
					name: "healthMan",
					component: () => import("@/pages/website/keepHealth"),
					meta: { title: "健康管理", code: 21201 },
					props: { type: "manage" }
				},
				{
					path: "/website/others",
					name: "others",
					component: () => import("@/pages/website/otherMenu"),
					meta: { title: "康复养生", code: 21201 },
					props: { type: "" }
				},
				{
					path: "/website/about",
					name: "about",
					component: () => import("@/pages/website/about"),
					meta: { title: "公司简介", code: 21201 },
					props: { type: "" }
				},
				{
					path: "/website/construct",
					name: "construct",
					component: () => import("@/pages/website/construct"),
					meta: { title: "组织架构", code: 21201 },
					props: { type: "" }
				}
			]
		},
		{
			path: "/app",
			meta: { title: "秉华健康", icon: "&#xe61a;", p: [14], code: 113, default: 0 },
			children: [
				{
					path: "",
					name: "recommend",
					component: () => import("@/pages/mini-app/recommend"),
					meta: { title: "健康推荐", code: 21301 }
				},
				{
					path: "/foodIngredient",
					name: "foodIngredients",
					component: () => import("@/pages/mini-app/food-ingredients"),
					meta: { title: "食材管理", code: 21303 }
				},
				// {
				//   path: "/dietTherapy",
				//   name: "dietTherapy",
				//   component: () => import("@/pages/mini-app/diet-therapy"),
				//   meta: { title: "食疗方", code: 21304 }
				// },
				{
					path: "/midnightnoon",
					name: "midnightNoon",
					component: () => import("@/pages/mini-app/midnight-noon"),
					meta: { title: "子午流注", code: 21305 }
				},
				{
					path: "/bonusPoints",
					name: "bonusPoints",
					component: () => import("@/pages/mini-app/bonus-points"),
					meta: { title: "消费积分管理", code: 21306 }
				},

			]
		},
		{
			path: "/bz",
			meta: { title: "秉华辨证", icon: "&#xe61a;", p: [14], code: 114, default: 0 },
			children: [
				{
					path: "",
					name: "bzDashbord",
					component: () => import("@/pages/vip-data/dashbord.vue"),
					meta: { title: "数据统计", code: 21401 }
				},
				{
					path: "bzTime",
					name: "bzTime",
					component: () => import("@/pages/vip-data/limit-time.vue"),
					meta: { title: "会员期限", code: 21401 }
				}
			]
		},
		{
			path: "/setting",
			meta: { title: "设置", icon: "&#xe7f9;", code: 115, default: 0 },
			children: [
				// {
				// 	path: "account",
				// 	name: "accountManage",
				// 	component: () => import("@/pages/headquarters/userManage.vue"),
				// 	meta: { title: "账号管理", code: 21501 }
				// },
				{
					path: "role",
					name: "roleManage",
					component: () => import("@/pages/headquarters/role.vue"),
					meta: { title: "角色管理", code: 21502 }
				},
				{
					path: "menu",
					name: "menuManage",
					component: () => import("@/pages/headquarters/menu.vue"),
					meta: { title: "菜单管理", code: 21503 }
				},
				{
					path: "log",
					name: "log",
					component: () => import("@/pages/s-smart-data/log.vue"),
					meta: { title: "操作日志", code: 21504 }
				},
				{
					path: "companyLists",
					name: "companyLists",
					component: () => import("@/pages/sys-setting/company-list"),
					meta: { title: "公司管理", code: 21504 }
				},
				// {
				// 	path: "/shoppingMall/marketPoints",
				// 	name: "shoppingMallPoints",
				// 	component: () => import("@/pages/mall/index.vue"),
				// 	meta: { title: "积分设置", code: 21701 },
				// 	props: {
				// 		type: 7
				// 	}
				// },
				{
					path: "/shoppingMall/marketDealer",
					name: "shoppingMallDealer",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "分销设置", code: 21701 },
					props: {
						type: 10
					}
				},
			]
		},
		{
			path: "/shoppingMall",
			meta: { title: "小程序", icon: "&#xe7f9;", p: [17], code: 117, default: 0 },
			children: [
				{
					path: "",
					name: "shoppingMall",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "统计概况", code: 21701 },
					props: {
						type: 0
					}
				},
				{
					path: "/shoppingMall/order",
					name: "shoppingMallOrder",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "订单管理", code: 21701 },
					props: {
						type: 1
					}
				},
				{
					path: "/shoppingMall/refound",
					name: "refoundList",
					component: () => import("@/pages/mall/refound-list.vue"),
					meta: { title: "退款列表", code: 21701 },
				},
				{
					path: "/shoppingMall/category",
					name: "shoppingMallCategory",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "商品分类", code: 21701 },
					props: {
						type: 2
					}
				},
				{
					path: "/shoppingMall/goodsList",
					name: "shoppingMallGoods",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "商品列表", code: 21701 },
					props: {
						type: 3
					}
				},
				{
					path: "/shoppingMall/goodRecommend",
					name: "goodRecommend",
					component: () => import("./pages/mall/goods-concect.vue"),
					meta: { title: "关联推荐", code: 21701 }
				},
				{
					path: "/shoppingMall/pageManage",
					name: "shoppingMallPage",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "页面设计", code: 21701 },
					props: {
						type: 4
					}
				},
				{
					path: "/shoppingMall/pageLink",
					name: "shoppingMallPageLink",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "页面链接", code: 21701 },
					props: {
						type: 5
					}
				},
				{
					path: "/shoppingMall/pageTemplate",
					name: "shoppingMallPageTemplate",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "分类模板", code: 21701 },
					props: {
						type: 6
					}
				},

				{
					path: "/shoppingMall/marketCoupon",
					name: "shoppingMallCoupon",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "优惠券", code: 21701 },
					props: {
						type: 8
					}
				},
				{
					path: "/shoppingMall/marketActive",
					name: "shoppingMallActive",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "促销活动", code: 21701 },
					props: {
						type: 9
					}
				},

				{
					path: "/shoppingMall/marketCardList",
					name: "shoppingMallCardList",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "礼品卡", code: 21701 },
					props: {
						type: 11
					}
				},
				{
					path: "/shoppingMall/marketWithdrawal",
					name: "shoppingMallWithdrawal",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "提现审核", code: 21701 },
					props: {
						type: 12
					}
				},
				{
					path: "/shoppingMall/marketSysSetting",
					name: "shoppingMallSysSetting",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "商城设置", code: 21701 },
					props: {
						type: 13
					}
				},
				{
					path: "/shoppingMall/pointsGoods",
					name: "pointsGoods",
					component: () => import("@/pages/mall/index.vue"),
					meta: { title: "积分商品", code: 21701 },
					props: {
						type: 14
					}
				}
				// {
				// 	path: "/shoppingMall/homePageSetting",
				// 	name: "pageSetting",
				// 	component: () => import("@/pages/mall/home-setting.vue"),
				// 	meta: { title: "主页设置", code: 21701 }
				// },
			]
		},
		{
			path: "/test",
			meta: { title: "实验", icon: "&#xe7f9;", p: [18], code: 118, default: 0 },
			children: [
				{
					path: "/test/demo",
					name: "experiment",
					component: () => import("./pages/experiment/demo.vue"),
					meta: { title: "健康管理DEMO", code: 21803 },
					props: true
				},
				{
					path: "/test/bloodStand",
					name: "bloodStand",
					component: () => import("@/pages/s-smart-data/blood-stand.vue"),
					meta: { title: "血压标准", code: 20807 }
				},
				{
					path: "/test/bloodTest",
					name: "bloodTest",
					component: () => import("./pages/patient/temp-test/blood-test.vue"),
					meta: { title: "血压计算", code: 21801 },
					props: true
				},
				{
					path: "/test/westTreat",
					name: "westTreat",
					component: () => import("./pages/patient/west-science.vue"),
					meta: { title: "西医诊疗(测试)", code: 21802 },
					props: true
				},
				{
					path: "/test/newHealthMan",
					name: "NhealthMan",
					component: () => import("./pages/patient/temp-test/new-health-m.vue"),
					meta: { title: "新健康评估", code: 21804 },
					props: true
				},
				{
					path: "/test/openapi",
					name: "openapi",
					component: () => import("./pages/patient/temp-test/open-api-v2.vue"),
					meta: { title: "健康评估3合1", code: 21805 },
					props: true
				},
			]
		}

		// {
		// 	path: "/menuMan",
		// 	meta: { title: "菜单", icon: "&#xe7f9;", p: [15], code: 115 },
		// 	children: [
		// 		{
		// 			path: "",
		// 			name: "sysMenus",
		// 			component: () => import("@/pages/menus/menu.vue"),
		// 			meta: { title: "菜单管理", code: 21501 }
		// 		}
		// 	]
		// }
		// {
		// 	path: "/statistical",
		// 	meta: { title: "数据统计", icon: "&#xe603;", p: [14], code: 113 },
		// 	children: [
		// 		{
		// 			path: "",
		// 			name: "datachart",
		// 			component: () => import("@/pages/charts/data-charts"),
		// 			meta: { title: "健康推荐", code: 21301 }
		// 		}
		// 	]
		// },

	],
	comPages: [
		{
			path: "/login",
			name: "login",
			meta: { title: "登录", icon: "&#xe7f9;" },
			component: login
		},
		{
			path: "/",
			name: "home",
			meta: { title: "秉华健康" },
			component: Home
		},
		// {
		//   path: "/dataCharts",
		//   name: "dataCharts",
		//   meta: { title: "数据报表" },
		//   component: () => import("./pages/charts/data-charts.vue")
		// },
		{
			path: "/changePassword",
			name: "changePassword",
			meta: { title: "修改密码" },
			component: () => import("./pages/system/change-password.vue")
		},
		{
			path: "/accountInfo",
			name: "accountInfo",
			meta: { title: "账号信息" },
			component: () => import("./pages/system/user-detail.vue")
		},
		{
			path: "/introduction",
			name: "introduction",
			meta: { title: "帮助" },
			component: () => import("./pages/system/system.vue")
			// children: [
			//   {

			//   },
			//   // {
			//   //   path: "update",
			//   //   name: "update",
			//   //   component: () => import("./pages/system/release-note.vue"),
			//   //   meta: { title: "更新日志" }
			//   // },
			//   {
			//     path: "down",
			//     name: "down",
			//     meta: { title: "资料下载" },
			//     component: () => import("./pages/system/down.vue")
			//   }
			// ]
		}
	]
};
