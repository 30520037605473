<template>
	<div class="tin-app" :class="{ 'no-menu': !showLeft }">
		<div class="tin-view-top">
			<TView :t-view-menu="tViewMenu" @transDefault="getActive" />
		</div>

		<div class="tin-view-main">
			<div class="tin-view-left">
				<LView ref="leftM" :currentName="currentName" :l-view-menu="lViewMenu" />
			</div>
			<div class="tin-view-right">
				<MView>
					<slot />
				</MView>
			</div>
		</div>
	</div>
</template>

<script>
import LView from "./components/LView";
import MView from "./components/MView";
import TView from "./components/TView";
export default {
	name: "Tin",
	components: {
		LView,
		MView,
		TView
	},
	data () {
		return {
			userMenu: [],
			lViewMenu: [],
			tViewMenu: [],
			active: '',
			currentName: ""
		};
	},
	computed: {
		showLeft () {
			return ![
				"home",
				"patientList",
				"doctorList",
				"outpatientList",
				"companyList",
				"datachart", "changePassword", "introduction", "accountInfo",
			].includes(this.$route.name);
		}
	},
	methods: {

		getActive (e) {
			// console.log("active", e)
			this.active = ""
			// this.active = e + ''
			this.currentName = e.name
			this.resetLeftMenu(this.$route);
			this.$store.commit("changeAct", e.active)
		},
		//创建二维数组
		creatArr (len) {
			let arr = new Array(len);
			for (let i = 0; i < arr.length; i++) {
				arr[i] = new Array();
			}
			return arr;
		},
		resetLeftMenu (to) {
			const currentName = to.name;
			// console.log('currentName', currentName)
			// if (currentName) {
			// this.$store.commit("changeAct", item.meta.default)
			this.lViewMenu = [];
			this.$router.options.routes
				.filter(item => !("com" in item))
				.forEach((item, k) => {
					let sunMenuStrArr = []
					item.children.filter((tar) => {
						return tar.subMenu
					}).map(sub => {
						let arr = sub.subMenu
						sunMenuStrArr.push(...arr)
					})
					// console.log('111111111', sunMenuStrArr)

					// let subMenuArr = subMenu.map(sub => {
					//   return sub.subMenu
					// })
					let cNameArr = item.children.map(itemc => itemc.name)
					// console.log("subMenuArr", subMenuArr)
					// console.log("subMenuArr--kkkk", subMenuArr[k])
					// console.log("cNameArr", cNameArr)
					if (item.children.map(itemc => itemc.name).includes(currentName)) {
						// console.log('111')
						if ("meta" in item.children[0]) {
							this.lViewMenu = item.children;
						}
					} else {
						if (sunMenuStrArr.includes(currentName)) {
							// console.log('2222')
							this.lViewMenu = item.children;
						}
					}



					// if (item.children.map(itemc => itemc.name).includes(currentName) && subMenu.length === 0) {
					//   console.log('111')
					//   if ("meta" in item.children[0]) {
					//     this.lViewMenu = item.children;
					//   }
					// } else {
					//   if (item.children.map(itemc => itemc.name).includes(currentName)) {
					//     console.log('2222')
					//     this.lViewMenu = item.children;
					//   }
					// }
				});
			// }
		}
	},
	watch: {
		$route (to) {
			// console.log('routechange')
			this.resetLeftMenu(to);
		}
	},
	created () {
		// console.log("Tin", this.$store.state.app.active)

		// console.log('this.$route', this.$router)
		let currentName = this.$route.name
		if (currentName === 'patientList') {
			this.$store.commit("changeAct", '1')
		}
		// this.resetLeftMenu(this.$route);
		this.$get("/bh/jk/account/userinfo").then(e => {
			this.tViewMenu = this.$router.options.routes
				.filter(item => {
					return !("com" in item);
				})
				.map(item => {
					if (item.children[0].path.indexOf(":") === -1) {
						return {
							...item,
							toPath: { name: item.children[0].name }
						};
					} else {
						if (item.children[0].path.indexOf(":") === -1) {
							return { ...item, toPath: { name: item.children[0].name } };
						} else {
							let id = "-";
							switch (item.meta.code) {
								case 102:
									id = e.data.userId;
									break;
								case 103:
									id = e.data.clinicId;
									break;
								case 104:
									id = e.data.companyId;
									break;
								default:
									break;
							}
							return {
								...item,
								toPath: { name: item.children[0].name, params: { id } }
							};
							// this.$router.push("/" + item.path + "/" + this.getUserInfo().id);
						}
					}
				});
		});
	}
};
</script>

<style scoped>
.tin-app {
	width: 100%;
	height: 100%;
}

.tin-view-top {
	position: absolute;
	width: 100%;
	top: 0;
	height: 40px;
	z-index: 3000;
}

.tin-view-left {
	width: 170px;
	height: 100%;
	position: absolute;
	left: 0;
	background-color: #f5f5f5;
	border: 1px solid #f2f2f2;
}

.tin-chart-right {
	width: auto;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 200px;
	background-color: #208164;
}

.tin-view-right {
	width: auto;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 170px;
	background-color: #208164;
}

.no-menu .tin-view-left {
	display: none;
}

.no-menu .tin-view-right {
	left: 0;
}

.tin-view-main {
	position: absolute;
	top: 40px;
	right: 0;
	left: 0;
	bottom: 0;
	background: #fff;
}
</style>

<style>
html,
body {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	color: #333;
	overflow: hidden;
	font-size: 12px;
	box-sizing: border-box;
	font-family: Roboto, "Segoe UI", Arial, "Microsoft Yahei", sans-serif;
}
</style>
