<template>
  <div class="tin-mview">
	  <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.tin-mview {
	width: 100%;
	height: 100%;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px;
}
</style>