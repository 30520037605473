<template>
  <div class="page-view">
    <slot/>
  </div>
</template>
<script>
export default {
  name: "page-view",
  data() {
    return {};
  },
  methods: {},
  created() {}
};
</script>

<style scoped>
.page-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
}
</style>
