//验证
// 账号
let codeReg = /^(?![0-9]*$)(?![a-zA-Z]*$)[a-zA-Z0-9]{6,20}$/;
// 电话
let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
// 必须为数字
let numberReg = /^\d+$|^\d+[.]?\d+$/;
// 密码
let passwordReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/;
// 联系人
let contactsReg = /^[\u0391-\uFFE5A-Za-z]+$/;
// 身份证
let regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
// email
let emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
// 出生日期
let birthReg = /^((?:19[2-9]\d{1})|(?:20(?:(?:0[0-9])|(?:1[0-8]))))\-((?:0?[1-9])|(?:1[0-2]))\-((?:0?[1-9])|(?:[1-2][0-9])|30|31)$/;
let FormValidate = (function() {
  function FormValidate() {}
  // From表单验证规则  可用于公用的校验部分
  FormValidate.Form = function() {
    return {
      // 账号的验证规则
      validateCode(rule, value, callback) {
        if (!value) {
          return callback(new Error("请输入账号"));
        }
        if (!codeReg.test(value)) {
          callback(new Error("账号必须为6-20位字母和数字组合"));
        } else {
          callback();
        }
      },

      // 只能数字的验证
      validateNumber(rule, value, callback) {
        if (value !== "") {
          if (!numberReg.test(value)) {
            callback(new Error("电话只能为数字"));
          } else {
            callback();
          }
        } else {
          callback(new Error("电话不能为空"));
        }
      },
      // 验证出生日期
      validateBirth(rule, value, callback) {
        if (value) {
          if (!birthReg.test(value)) {
            callback(new Error("出生日期格式错误,例:19xx-01-01"));
          } else {
            callback();
          }
        } else {
          callback(new Error("出生日期不能为空"));
        }
      },
      // 出生日期年份
      validateBirthYear(rule, value, callback) {
        if (value != "") {
          let myDate = new Date();
          let myYear = myDate.getFullYear();
          if (value > myYear || value < 1900) {
            callback(new Error("请输入正确年份"));
          } else {
            callback();
          }
        } else {
          callback(new Error("年份不能为空"));
        }
      },
      // 出生日期月份
      validateBirthMonth(rule, value, callback) {
        if (value != "") {
          if (value > 12 || value < 1) {
            callback(new Error("请输入正确月份"));
          } else {
            callback();
          }
        } else {
          callback(new Error("月份不能为空"));
        }
      },
      // 出生日期天
      validateBirthDays(rule, value, callback) {
        if (value != "") {
          if (value > 31 || value < 1) {
            callback(new Error("请输入正确日期"));
          } else {
            callback();
          }
        } else {
          callback(new Error("日期不能为空"));
        }
      },
      // 密码的验证
      // validatePsdReg(rule, value, callback) {
      //   if (!value) {
      //     return callback(new Error("请输入密码"));
      //   }
      //   if (!passwordReg.test(value)) {
      //     callback(
      //       new Error(
      //         "请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种"
      //       )
      //     );
      //   } else {
      //     callback();
      //   }
      // },

      //密码不为空
      validatePsdReg(rule, value, callback) {
        if (!value) {
          return callback(new Error("请输入密码"));
        } else {
          callback();
        }
      },

      // 真实姓名
      validateContacts(rule, value, callback) {
        if (!value) {
          return callback(new Error("请输入真实姓名"));
        }
        if (!contactsReg.test(value)) {
          callback(new Error("真实姓名不可输入特殊字符"));
        } else {
          callback();
        }
      },
      //options类型必选
      validateOptions(rule, value, callback){
        if(!value){
          return callback(new Error("该类型必选"));
        } else {
          callback();
        }
      },
      validatePermission(rule, value, callback) {
        if( value.length === 0 ) {
          return callback(new Error("请勾选权限"));
        } else {
          callback();
        }
      },
      //不为空验证
      validateNotNull(rule, value, callback){
        // console.log('asdasd', value)
        if(!value){
          return callback(new Error("不能为空"));
        } else {
          callback();
        }
      },
      //主治病症
      validateMainCure(rule, value, callback){
        if(!value){
          return callback(new Error("主治病症必填"));
        } else {
          callback();
        }
      },
      //医生介绍
      validateIntroduce(rule, value, callback){
        if(!value){
          return callback(new Error("简介必填"));
        } else {
          callback();
        }
      },
      //certificateUrl
      validateCertificate(rule, value, callback){
        if(!value){
          return callback(new Error("请上传营业执照/行医资格证"));
        } else {
          callback();
        }
      },
      // 邮箱的验证规则
      validateEmail(rule, value, callback) {
        if (value !== "") {
          if (!emailReg.test(value)) {
            callback(new Error("邮箱格式不正确"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      },

      // 电话号码的验证
      validatePhone(rule, value, callback) {
        if (!value) {
          return callback(new Error("请输入手机号码"));
        }
        if (!phoneReg.test(value)) {
          callback(new Error("手机格式不正确"));
        } 
        else {
          callback();
        }
      },

      // 身份证的验证规则
      idCard(rule, value, callback) {
        if (!value) {
          return callback(new Error("身份证不能为空"));
        }
        if (!regId.test(value)) {
          callback(new Error("请输入正确的二代身份证号码"));
        } else {
          callback();
        }
      }
    };
  };

  return FormValidate;
})();

exports.FormValidate = FormValidate;
