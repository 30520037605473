<template>
	<div class="login">
		<div class="out-box">
			<div></div>
			<div class="login-box" v-if="!isLogin">
				<div class="login__main-title">
					<img :src="logoSrc" v-if="logoSrc" alt />
					<div class="flex-ske" v-else>
						<el-skeleton>
							<template slot="template">
								<el-skeleton-item variant="image" style="width: 80px; height: 80px;margin-bottom: 30px" />
							</template>
						</el-skeleton>
					</div>
				</div>
				<el-form ref="form" :model="form" :rules="rules" label-width="55px" label-position="left">
					<el-form-item label="账号:">
						<el-input v-model="form.name" class="width300" placeholder="请输入账号"></el-input>
					</el-form-item>
					<el-form-item label="密码:">
						<el-input v-model="form.password" class="width300" placeholder="请输入密码" type="password" show-password
							@keyup.enter.native="onSubmit">
						</el-input>
						<el-col :span="12">
							<el-checkbox v-model="isRember">记住密码</el-checkbox>
						</el-col>
						<el-col :span="6"><span style="padding:0 14px;"></span></el-col>
						<el-col :span="5">
						</el-col>
					</el-form-item>
				</el-form>
				<div style="text-align:center;">
					<el-button class="login__main-btn" type="primary" @click="onSubmit" block>登录</el-button>
					<div v-if="!isClient" style="padding-top:10px;">
						<el-link type="primary" :underline="underline" style="font-size:12px;" target="_blank"
							:href="downloadPath">下载客户端</el-link>
					</div>
				</div>
			</div>

			<div class="support">
				<div class="s-font">
					<span>技术支持:</span>
					<span>秉华健康</span>
				</div>

			</div>
		</div>
		<el-dialog title="" :visible.sync="showAgreement" top="10vh" width="55%">
			<div class="dialogContent">
				<p style=";margin-bottom:10px;text-align:center;line-height:150%;background:white">
					<strong><span
							style="font-size:29px;line-height: 150%;font-family:宋体;color:#333333;background:white">&nbsp;免责声明</span></strong>
				</p>
				<p
					style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left: 0;text-indent:28px;line-height:150%;background: white">
					<span
						style="font-family:宋体;color:black">凡以任何方式登录“秉华健康”平台，请您务必仔细阅读并透彻理解本声明，如果您使用“秉华健康”平台，您的使用行为将被视为对本声明全部内容的认可。</span>
				</p>
				<p
					style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left: 0;text-indent:28px;line-height:150%;background: white">
					<span
						style="font-family:宋体;color:black">一、“秉华健康”平台如因不可抗力或下列状况无法正常运作，导致网上交易无法完成或丢失有关的信息、记录等，网站将不承担责任。但是我们将会尽合理的可能协助处理善后事宜，并努力使客户减少可能遭受的经济损失。该状况包括但不限于：</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">云平台系统停机升级或维护期间。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">2) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">电信设备出现故障不能进行数据传输的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">3) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">基础云平台服务提供商故障导致的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">4) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">5) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">6)</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">网络状况、通讯线路、第三方网站及其造成的各种损失（包括因下载被搜索链接到的第三方网站内容而感染电脑病毒）</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">二、“秉华健康”平台提供的各种诊断测验均为本站成员原创或整理，版权归作者与本站共同所有。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">若作者有版权声明的或文章从其他网站转载而附带有原所有站的版权声明者，其版权归属以附带声明为准。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">三、“秉华健康”平台所提供的各种信息不被视为医生诊断处方权，并做如下规定：</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">、“秉华健康”平台仅作为医生诊疗过程中的辅助参考，一切诊断与治疗均为医生的行为。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">2</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">、如医生利用“秉华健康”平台出现医疗纠纷，医生承担全部责任，与“秉华健康”平台无关。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">四、“秉华健康”平台有与其它网站的链接，所链接的网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，“秉华健康”平台对其合法性概不负责，亦不承担任何法律责任。“秉华健康”平台在收到上述法律文件后，将会依法尽快断开相关链接内容或在三个工作日内修改或删除转载相关内容。详情参见特定频道的著作权保护声明。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">五、除“秉华健康”平台注明的服务条款外，秉华云健康平台不做以下任何形式的保证：不保证所有辅助诊疗、用药、查询结果满足使用者的需求；不保证搜索结果的安全性、正确性、及时性、合法性；不保证搜索服务不中断。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">六、用户发布内容时，即表示该用户许可本公司所属公司及关联公司、复制、展览、演示、传播、翻译、编辑和创作派生等信息，本公司尊重该用户作为作者对内容拥有分享产权，作者有权在任何时间要求本公司在我们的应用及/或网站中将其删除，本公司承诺始终对个人信息进行保密。</span>
				</p>
				<p style="margin-left:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">七、用户不得发布或上传含有以下内容的信息：</span>
				</p>
				<p style="margin-left:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">）欺诈、违法或误导信息；<br />
						2）侵犯他人分享产权、著作权或隐私的信息；</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">鼓励用户将网站中不合时宜或非法内容通知本站，我们将保留无理由删除任何发布内容的权利。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">八、“秉华健康”平台在线问答系统是为网友提供的服务性公共性交流平台，回复者为中医专家和医生，亦有本网在线工作人员。回答问题仅针对提问者描述的证状提供建议性内容，不能作为诊疗的依据。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">本声明及其修改权、更新权、最终解释权均属江苏秉华健康科技有限公司所有。&nbsp;</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">感谢您对本条款的阅读，“秉华健康”平台衷心祝您健康、平安！</span>
				</p>
				<p style="line-height: 150%;">
					<span style="font-size: 16px;line-height:150%;font-family:宋体">&nbsp;</span>
				</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showAgreement = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// hospital-门诊  pharmacy-药房 nurse-调理师 doc-医生  assistant-助理
import rules from "@/util/rules";
export default {
	name: "Login",
	data () {
		return {
			userName: "",
			password: "",
			isLogin: false,
			ruleForm: {
				name: "",
				idCard: "",
				phone: "",
				company: "",
				clinic: "",
				hospital: "",
				smscode: "",
				pass: "",
				introduce: "",
				mainCure: "",
				certificateUrl: "",
				options: []
			},
			buttonText: "发送验证码",
			isDisabled: false,
			flag: true,
			agree: false,
			showAgreement: false,
			rules: {
				certificateUrl: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateNotNull,
						trigger: "blur"
					}
				],
				companyHospital: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateNotNull,
						trigger: "blur"
					}
				],
				clinic: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateNotNull,
						trigger: "blur"
					}
				],
				smscode: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateNotNull,
						trigger: "blur"
					}
				],
				mainCure: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateMainCure,
						trigger: "blur"
					}
				],
				name: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateContacts,
						trigger: "blur"
					}
				],
				password: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateContacts,
						trigger: "blur"
					}
				],
				phone: [
					{
						required: true,
						validator: rules.FormValidate.Form().validatePhone,
						trigger: "blur"
					}
				],
				introduce: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateIntroduce,
						trigger: "blur"
					}
				],
				options: [
					{
						required: true,
						validator: rules.FormValidate.Form().validateOptions,
						trigger: "blur"
					}
				],
				idCard: [
					{
						required: true,
						validator: rules.FormValidate.Form().idCard,
						trigger: "blur"
					}
				]
			},
			imageUrl: "",
			picUrl: "",
			options: [
				{ code: "culinarian", label: "调理师" },
				{ code: "doctor", label: "医生" },
				{ code: "medicalhelp", label: "医助" }
			],
			company: [],
			hospital: [],
			certificateUrl: "",
			companyHospital: [],
			selectOptions: [],
			isLoading: false,
			//new
			form: {
				name: "",
				password: ""
			},
			isRember: false,
			isClient: false, //是否客户端
			underline: false,
			logoSrc: "",
			downloadPath: ""
		};
	},
	//
	methods: {
		emitEmpty () {
			this.$refs.userNameInput.focus();
			this.userName = "";
		},
		onSubmit () {
			if (this.form.name === "") {
				this.$message.error("账号不能为空");
				return;
			}
			if (this.form.password === "") {
				this.$message.error("请填写密码");
				return;
			}

			// /bh/jk/login/main
			///bh/jk/account/login
			this.$post("/bh/jk/login/main", {
				// this.$post("/bh/jk/account/login", {
				name: this.form.name,
				password: this.form.password
			})
				.then(res => {
					//4003密码错误 4004用户不存在
					if (res.code === 200) {
						if (this.isRember) {
							let userLoginInfo = {
								userName: this.form.name,
								password: this.form.password,
								isRember: this.isRember
							};
							localStorage.setItem(
								"userLoginInfo",
								JSON.stringify(userLoginInfo)
							);
						} else {
							localStorage.removeItem("userLoginInfo");
						}
						localStorage.setItem("auth", res.data.token);
						localStorage.setItem(
							"user-permission",
							JSON.stringify(res.data.sysMenuList)
						);
						localStorage.setItem("isAgree", res.data.isAgree);
						location.assign("/");
					} else {
						this.showErr(res.code, res.data);
					}
				});
		},
		showErr (code, message) {
			// if (code === 4003) {
			//   this.$message({
			//     message: "密码错误",
			//     type: "warning"
			//   });
			// }
			let msg = "";
			switch (code) {
				case 4003: {
					msg = "密码错误，请输入正确密码";
					break;
				}
				case 4004: {
					msg = "账号不存在";
					break;
				}
				case 4006: {
					msg = message;
					break;
				}
				default: {
					msg = message;
					break;
				}
			}
			this.$message({
				message: msg,
				type: "warning"
			});
		},
		register () {
			this.isLogin = true;
			this.$get("/bh/company/tree").then(res => {
				let companyClinic = [];
				res.data.map(item => {
					let clinic = item.hospitalList.map(items => {
						return {
							value: items.code,
							label: items.name
						};
					});
					companyClinic.push({
						value: item.id,
						label: item.companyName,
						children: clinic
					});
				});
				this.selectOptions = companyClinic;
			});
		},
		sendCode () {
			let tel = this.ruleForm.phone;
			if (this.checkMobile(tel)) {
				this.$post("/bh/sms/sendsms/" + tel).then(() => {
					let time = 60;
					this.buttonText = "已发送";
					this.isDisabled = true;
					if (this.flag) {
						this.flag = false;
						let timer = setInterval(() => {
							time--;
							this.buttonText = time + " 秒";
							if (time === 0) {
								clearInterval(timer);
								this.buttonText = "重新获取";
								this.isDisabled = false;
								this.flag = true;
							}
						}, 1000);
					}
				});
			} else {
				this.$message.error("检查手机号是否填写正确");
			}
		},
		checkMobile (str) {
			let re = /^1\d{10}$/;
			if (re.test(str)) {
				return true;
			} else {
				return false;
			}
		},
		openAgreement () {
			this.showAgreement = true;
		},
		submitForm (ruleForm) {
			this.$refs[ruleForm].validate(valid => {
				if (valid) {
					this.submitData(ruleForm);
				} else {
					return false;
				}
			});
		},

		handleAvatarSuccess (res, file) {
			this.picUrl = res.data;
			this.imageUrl = URL.createObjectURL(file.raw);
		},
		handleCertificateSuccess (res, file) {
			this.ruleForm.certificateUrl = res.data;
			this.certificateImgUrl = URL.createObjectURL(file.raw);
		},
		handleCertificateRemove () {
			this.ruleForm.certificateUrl = "";
		},
		beforeAvatarUpload (file) {
			const isJPG = file.type === "image/jpeg";
			const isLt2M = file.size / 1024 / 1024 < 1;

			if (!isJPG) {
				this.$message.error("上传头像图片只能是 JPG 格式!");
			}
			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 1MB!");
			}
			return isJPG && isLt2M;
		},
		back () {
			this.isLogin = false;
			this.resetForm("ruleForm");
		},
		//2医生 3药房 6调理师 13医助
		submitData (ruleForm) {
			// let addDoctorURL = "/bh/doctor/front";
			// let addCulinarianURL = "/bh/doctor/culinarian/front";
			// let addMhelpURL = "/bh/doctor/mhelp/front";
			// let addWareHouseURL = "/bh/sysUser/warehouse";
			this.isLoading = true;
			let url = "/bh/doctor/register";
			let options = this.$refs[ruleForm].model.options;
			this.submitDataPost(url, options);
		},
		submitDataPost (url, options) {
			let phone = this.ruleForm.phone;
			let smscode = this.ruleForm.smscode;
			let companyClinicId = this.companyHospital;
			this.$post("/bh/sysUser/register/" + smscode + "/" + phone).then(() => {
				this.$post(url + "?type=" + options, {
					name: this.ruleForm.name,
					cardid: this.ruleForm.idCard,
					mobile: this.ruleForm.phone,
					introduction: this.ruleForm.introduce,
					bindHospital: this.ruleForm.clinic,
					companyId: companyClinicId[0],
					certificate: this.ruleForm.certificateUrl,
					readTerms: 1
				}).then(() => {
					this.submitSuccess();
				});
			});
		},
		submitSuccess () {
			this.isLoading = false;
			this.$alert(
				"注册已提交，请耐心等待管理员的审核,审核通过之后会以短信形式告知您的账号密码!",
				"提示",
				{
					confirmButtonText: "确定",
					showClose: false,
					callback: action => {
						this.$router.go(0);
					}
				}
			);
		},
		resetForm (ruleForm) {
			this.$refs[ruleForm].resetFields();
			this.companyHospital = [];
		},
		handleChange () {
			let companyClinic = this.companyHospital;
			let clinicCode = this.companyHospital[1];
			this.ruleForm.company = companyClinic[0];
			this.ruleForm.clinic = clinicCode;
		}
	},
	created () {
		// this.$get('/bh/jk/device/info/1593476311416864')
		this.$get("/bh/jk/config/init").then(res => {
			this.logoSrc = res.data.logo_url + '?imageMogr2/thumbnail/200x200!'
			this.downloadPath = res.data.downPath
			if (res.data.abbr === "binghua") {
				document.title = "秉华健康"
			} else {
				document.title = "正中堂健康"
			}
		})

		// const debug = process.env.NODE_ENV !== "production";
		// if (debug) {
		//   this.form.name = "HD004";
		//   this.form.password = "wy112233";
		// }
		if (typeof election != "undefined") {
			this.isClient = true;
		}
		let loginStr = localStorage.getItem("userLoginInfo");
		if (loginStr) {
			let userLoginInfo = JSON.parse(loginStr);
			// console.log("userLoginInfo",userLoginInfo)
			this.form.name = userLoginInfo.userName;
			this.form.password = userLoginInfo.password;
			this.isRember = userLoginInfo.isRember;
		}
	}
};
</script>

<style lang="scss" scoped>
.width300 {
	width: 300px;
}

.login {
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	background-image: url("../../assets/background3.jpg");
	background-repeat: no-repeat;
	background-position: center 20%;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	// border: 1px solid #000;
}

.out-box {
	// width: 440px;
	height: 85vh;
	// border: 1px solid rgb(212, 20, 20);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.login-box {
	width: 440px;
	height: 390px;
	background-color: #fff;
	margin-bottom: 50px;
	padding: 30px;
	border: 2px solid #bdbdbd;
	box-shadow: 2px 10px 15px #888888;
}

.support {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.s-font {
	text-align: center;
}

.s-font span {
	color: #686868;
}

.login__main-title {
	text-align: center;
}

.flex-ske {
	display: flex;
	justify-content: center;
}

.login__main-title img {
	width: 80px;
	height: 80px;
	margin-bottom: 30px;
	border-radius: 20px;
}

.login__main-input {
	margin-bottom: 20px;
	height: 40px;
}

.login__main-btn {
	height: 40px;
	width: 100%;
}

.to_register {
	text-align: center;
	font-size: 13px;
	color: #606266;
	margin-top: 20px;
	cursor: pointer;
}

.to_register:hover {
	text-align: center;
	font-size: 13px;
	color: #888;
	text-decoration: underline;
}

.register__main {
	width: 520px;
	height: auto;
	background-color: #fff;
	margin-bottom: 100px;
	padding: 30px;
	box-shadow: 2px 10px 15px #888888;
}

.btn-area {
	display: flex;
	justify-content: space-around;
}

.btn-area button {
	width: 100px;
}

.line {
	text-align: center;
}

.avatar {
	width: 100%;
}

.avatar-uploader {
	width: 100%;
}

.img-btn-area {
	display: flex;
	justify-content: space-around;
	height: 30px;
	width: 100%;
	align-items: center;
}

.el-upload {
	width: 100% !important;
}

.agreeMent {
	height: 20px;
	display: flex;
	align-items: center;
}

.agreeDetail {
	padding-left: 5px;
	color: #1d94d1;
	cursor: pointer;
}

.dialogContent {
	height: 600px;
	overflow: auto;
}
</style>
