<template>
	<div id="app" v-show="!$root.init">
		<!-- <Tin v-if="!loading && view && user"> -->
		<div v-if="!starUpdate">
			<Tin v-if="$root.isLogin !== false">
				<router-view v-if="isRouterAlive"></router-view>
			</Tin>
			<Login v-if="$root.isLogin === false" />
		</div>
		<!-- <div v-else class="loading">
      <el-progress type="circle" :stroke-width="18" :percentage="downLoadPercent" :width="200"></el-progress>
      <p style="padding: 15px 0;" v-if="downLoadPercent<100">正在下载中...</p>
      <p style="padding: 15px 0;" v-else>下载完成</p>
    </div> -->
		<!-- 更新 -->
		<el-dialog title="更新提示" :visible.sync="hasNewVersion" append-to-body lock-scroll :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" width="45%" top="280px">
			<p>检测到有新版本，为保证系统正常运行，请立即更新！</p>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="toUpdate">立即更新</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import version from "../../../public/version";
import axios from "axios";
export default {
	name: "app",
	provide () {
		return {
			reload: this.reload
		};
	},
	// components: {
	//   Login
	// },
	data () {
		return {
			isRouterAlive: true,
			view: false,
			hasNewVersion: false,
			canUpdate: false, //是否有新版本
			starUpdate: false, //是否开始下载
			updateStatus: false, //下载状态
			downLoadPercent: 90 //进度
		};
	},
	computed: {
		// ...mapGetters(["user", "loading"])
	},
	methods: {
		toUpdate () {
			// this.canUpdate = false;
			// this.starUpdate = true;
			sdk.update().quitAndInstall();
		},
		reload () {
			this.isRouterAlive = false;
			this.$nextTick(function () {
				this.isRouterAlive = true;
			});
		}
	},
	created () {
		// console.log('2222', this.$route);
		// axios.get("/bh/jk/config/init").then(res => {
		//   // console.log("res", res.data.data)
		//   if (res.data.data.abbr === "binghua") {
		//     document.title = "秉华健康"
		//   } else {
		//     document.title = "正中堂健康"
		//   }
		// })
		this.view = true;
		// setTimeout(() => {
		//   this.hasNewVersion = true;
		//   this.starUpdate = true
		// }, 3000);
		// console.log("election",election)
		if (typeof election === "undefined") {
			return;
		} else {
			sdk.update().check(status => {

				console.log("status", status);
				if (status.code === "download") {
					this.hasNewVersion = true;
					this.starUpdate = true;
				}
			});
		}
	}
};
</script>

<style lang="scss">
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

.el-message {
	z-index: 3001 !important;
}

.el-notification {
	z-index: 3001 !important;
}
</style>
