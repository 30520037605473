import axios from "axios";
import qs from "qs";
import { MessageBox } from "element-ui";

const debug = process.env.NODE_ENV !== "production";

const request = axios.create({
	// headers: { "Content-Type": "text/plain" },
	// headers: { "Content-Type": "application/x-www-form-urlencoded" },
	// headers: { "Content-Type": "application/json" },
	// transformRequest: [
	//     function(data) {
	//         return qs.stringify(data);
	//     }
	// ]
	// transformRequest: [
	//   function(data) {
	//     // 对 data 进行任意转换处理
	//     return qs.stringify(data);
	//   }
	// ]
});
axios.defaults.timeout = 30000
const ignoreList = ["/bh/jk/account/check"];

request.interceptors.response.use(

	function (response) {
		// console.log("1111111111111111", typeof (response.data))
		// console.log("responseresponseresponse", response)
		// Message.error(123123);
		if (debug) {
			/* eslint-disable no-console */
		}
		if (typeof (response.data) === 'string') {
			let img = btoa(
				new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
			);
			response.data = 'data:image/png;base64,' + img;
		}

		// base code
		// SUCCESS(200,"操作成功"),
		// BAD_REQUEST(400, "错误请求"),     // 弹出层
		// FORBIDDEN(403, "没有相关权限"),    // 弹出层
		// SERVER_ERROR(500,"服务器错误"),    // 弹出层
		// BUSINESS_ERROR(550, "业务错误"),

		// ERR_REQUEST_EXIST(4201, "数据已存在"),

		// // 登录错误4001~4100
		// UNAUTHORIZED(4001, "用户未登录，请先登录"),
		// LOGIN_EXPIRED(4002, "登录过期，请重新登录"),
		// LOGIN_PASSWORD_ERROR(4003, "密码错误"),
		// USER_NOT_EXIST(4004, "用户不存在"),
		// USER_REMOVE_LOGIN(4005, "账户异地登录"),
		// USER_FORBIDDEN(4006, "账户已经禁用"),
		// console.log("123123", response);
		if (
			!ignoreList.includes(response.config.url) &&
			[400, 403, 500, 550, 555, 4201, 4001, 4002, 4003, 4004, 4005].includes(
				response.data.code
			)
		) {
			let currentPath = window.location.href;
			let test = currentPath.split("/#/");
			// console.log("2222", test);
			if (test[1] === "") {
				// throw response.data;
				// console.log("1111",test);
			} else {
				console.log("error");
				throw response.data;
			}
		}
		// if (response.data.code === 404) {
		// 	console.log("111")
		// 	// this.$router.go(-1)
		// }

		return Promise.resolve(response.data);
	},
	function (error) {
		// Toast.clear();
		// 对响应错误做点什么
	}
);

const checkDebug = function (param = {}, debugOpen = debug) {
	if (debugOpen) {
		param = Object.assign(param, { debug: true });
	}
	return param;
};

function showErrorInfo (data) {

	MessageBox.alert(`${data.msg ? data.msg : data.message}`, {
		confirmButtonText: "确定",
		type: "error",
		callback: action => {
			if ([4201, 4001, 4002, 4003, 4004, 4005, 4006].includes(data.code)) {
				location.assign("/")
			}
		}
	});
	throw "艹 系统 bug";

}

async function requestServerData ({ method, url, data, q, img }) {

	let token = localStorage.getItem("auth")


	try {
		return await request({
			method,
			url,
			data,
			headers: img
				? {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "multipart/form-data",
					Authorization: token
				}
				: q
					? {
						"Access-Control-Allow-Origin": "*",
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: token
					}
					: {
						"Access-Control-Allow-Origin": "*",
						"Content-Type": "application/json",
						Authorization: token
					},
			transformRequest: img
				? []
				: [
					function (data) {
						return q ? qs.stringify(data) : JSON.stringify(data);
					}
				]
		});
	} catch (e) {
		showErrorInfo(e);
	}
}
async function uploadIMG ({ method, url, data }) {
	try {
		return await request({
			method,
			url,
			data,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: localStorage.getItem("auth")
			}
		});
	} catch (e) {
		showErrorInfo(e);
	}
}
async function postForm ({ method, url, data }) {
	try {
		return await request({
			method,
			url,
			data,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: localStorage.getItem("auth")
			}
		});
	} catch (e) {
		showErrorInfo(e);
	}
}

// export const get = (url, params) => ;
export async function getQrCode (url, params) {
	try {
		return await request.get(url, {
			params: params,
			headers: {
				responseType: 'arraybuffer',
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("auth")
			}
		});
	} catch (e) {
		showErrorInfo(e);
	}
}


export async function getQs (url, params) {
	try {
		return await request.get(url, {
			params: params,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: localStorage.getItem("auth")
			}
		});
	} catch (e) {
		showErrorInfo(e);
	}
}

export async function postQs (url, params) {
	try {
		return await request.post(url, {
			params: params,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: localStorage.getItem("auth")
			}
		});
	} catch (e) {
		showErrorInfo(e);
	}
}

export async function get (url, data, q = false) {
	return await requestServerData({ method: "GET", url, data, q });
}

export async function post (url, data, q = false, img = false) {
	return await requestServerData({ method: "POST", url, data, q, img });
}
export async function postImg (url, data, q = false, img = true) {
	return await uploadIMG({ method: "POST", url, q, data, img });
}
export async function postForms (url, data, q = false, img = true) {
	return await postForm({ method: "POST", url, q, data, img });
}

export async function put (url, data, q = false, img = false) {
	return await requestServerData({ method: "PUT", url, data, q, img });
}

export async function del (url, data, q = false) {
	return await requestServerData({ method: "DELETE", url, data, q });
}
