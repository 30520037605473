<template>
	<div v-loading="loading">
		<div class="data-table-tabbar">
			<slot name="header"> </slot>
		</div>
		<el-table class="data-table-main" ref="multipleTable" :size="size" :id="id" border :style="'height:' + tableHeight"
			:data="data" @select="handleSelectionChange" :row-class-name="tableRowClassName" :span-method="spanMethod">
			<slot name="column"></slot>
		</el-table>
		<div style="height: 36px">

			<el-pagination :small="size === 'mini' ? true : false" @current-change="bindCurrentChange"
				@size-change="handleSizeChange" :current-page="icurrentPage" class="pagination"
				:background="size === 'mini' ? false : true"
				:layout="showPageSize ? 'total, sizes,prev, pager, next, jumper' : 'total, prev, pager, next, jumper'"
				:page-sizes="[10, 50, 100, 200,]" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
import PaginationWeb from "./pagination-web";
export default {
	name: "crudData",
	components: {
		PaginationWeb
	},
	props: {
		data: Array,
		icurrentPage: {
			type: Number,
			default: 1
		},
		tableHeight: {
			type: String,
			default: '100%'
		},
		total: Number,
		loading: Boolean,
		showPageSize: Boolean,
		size: String,
		spanMethod: Function,
		rowClassName: Function,
		testNum: Number, //1 变色

		id: String
	},
	data () {
		return {
			dataSelection: [[]],
			input: "",
			currentDataSelection: [],
			togglePage: false,
			currentPage: 1,
			feedBackNum: 2,
			columnIndex: 0,
		};
	},
	computed: {
		// 计算属性的 getter
		// tableData() {
		//   return this.data[this.currentPage - 1];
		// }
	},

	created () {
		// console.log("icurrentPage",this.icurrentPage)
	},

	methods: {
		handleSizeChange (e) {
			this.$emit("sizes-change", e);
		},
		tableRowClassName ({ row, rowIndex }) {
			if (this.testNum === 1) {
				console.log(row)
				if (row.name === "王朝梅") {
					return 'warning-row';
				}
				// if (rowIndex === 1) {
				//   return 'warning-row';
				// } else if (rowIndex === 3) {
				//   return 'success-row';
				// }
				// return '';
			} else {
				return ""
			}

		},

		bindCurrentChange (e) {
			this.currentPage = e;
			// this.$refs.multipleTable.clearSelection();
			// if (this.dataSelection[e-1] !== undefined) {
			//     this.dataSelection[e-1].forEach(row => {
			//         this.$refs.multipleTable.toggleRowSelection(row);
			//     });
			// }
			setTimeout(() => {
				this.onclick(e);
			}, 0);
			this.$emit("current-change", e);
		},
		handleSelectionChange (e) {
			this.$set(this.dataSelection, this.currentPage - 1, e);
		},

		onclick (e) {
			if (this.dataSelection[e - 1] !== undefined) {
				this.dataSelection[this.currentPage - 1].forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			}
		},
		objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === this.columnIndex) {
				if (rowIndex % this.feedBackNum === 0) {
					return {
						rowspan: this.feedBackNum,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
};
</script>
<style>
.el-table .warning-row {
	background: oldlace;
}
</style>

<style lang="scss" scoped>
.pagination {
	padding: 6px 0;
	text-align: right;
	background-color: #fff;
	border: 1px solid #ebeef5;
	border-top-width: 0;
}

.data-table-main {
	/*height: 600px;*/
	width: 100%;
	margin-top: 10px;
}

.data-table-tabbar {
	display: flex;

	.el-input {
		width: 260px;
	}

	justify-content: space-between;
}

.medicinal {
	background-color: #fff;
	padding: 10px;
}

.el-button--small,
.el-button--small.is-round {
	padding: 5px 15px;
}

.data-table-tabbar {
	display: flex;

	.el-input {
		width: 260px;
	}

	justify-content: space-between;
}
</style>
