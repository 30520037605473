<template>
  <div class="compatibility">
    <div class="title">温馨提示： 请使用秉华健康系统指定浏览器访问</div>

    <ul class="clearfix">
      <li>
        <a
          href="https://www.google.cn/chrome/browser"
          title="谷歌浏览器"
          class="browser"
          o2tag="32"
        >
          <img src="../assets/chrome.jpg" alt="chrome" />
          <p>谷歌浏览器</p>
        </a>
        <a
          href="https://www.google.cn/chrome/browser"
          title="下载"
          class="btn btn-primary"
          o2tag="33"
          >前往下载</a
        >
      </li>
      <li>
        <a href="//browser.qq.com/" title="QQ浏览器" class="browser" o2tag="34">
          <img src="../assets/qq.png" alt="QQ浏览器" />
          <p>QQ浏览器</p>
        </a>
        <a
          href="//browser.qq.com/"
          title="下载"
          class="btn btn-primary"
          o2tag="35"
          >前往下载</a
        >
      </li>
      <li>
        <a
          href="//browser.360.cn/"
          title="360浏览器"
          class="browser"
          o2tag="34"
        >
          <img src="../assets/360.png" alt="360浏览器" />
          <p>360浏览器</p>
        </a>
        <a
          href="//browser.360.cn/"
          title="下载"
          class="btn btn-primary"
          o2tag="35"
          >前往下载</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "compatibility"
};
</script>

<style scoped>
.compatibility {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 13px;
}

.title {
  text-align: center;
  margin-top: 100px;
  padding: 50px 0;
}

ul {
  display: inline-block;
  text-align: center;
  width: 360px;
}
ul li {
  float: left;
  width: 120px;
}

img {
  width: 60px;
  height: 60px;
}

a {
  color: #333;
  text-decoration: none;
}

.btn {
  padding: 5px 10px;
  background-color: #2196f3;
  font-size: 13px;
  color: #fff;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
</style>
