window.page = function(target) {
  target.mixins = [
    {
      data() {
        return {};
      },
      beforeCreate() {},
      created() {
        if (target.title) {
          document.title =
            target.title === undefined
              ? window.web_miniprogram.title
              : target.title;
        }
        // if (config.backgroundColor) {
        //     document.body.style.backgroundColor = config.backgroundColor;
        // }
        // if (window.web_miniprogram._shareMessage) {
        //     wxShare(window.web_miniprogram._shareMessage);
        // }
      },
      destroyed() {
        // if (config.backgroundColor) {
        //     document.body.style.backgroundColor = null;
        // }
      },
      computed: {
        q() {
          return this.$route.query;
        }
      },
      methods: {
        to(url, replace = false) {
          if (replace) {
            this.$router.replace(url);
          } else {
            this.$router.push(url);
          }
        },
        setTitle(titleText = "") {
          document.title = titleText;
        }
        // onShareAppMessage(data) {
        //     wxShare(data)
        // }
      }
    }
  ];
  target.call(this);
  return target;
};
