import "@/util/p";
import Vue from "vue";
import util from "@/util";
import store from "./store";
import config from "@/config.js";
import Login from "@/pages/login/login";
import "./app.scss";
import TinMain from "@/components/menu/main";
import areaData from "@/components/area/data.json";
import dataV from "@jiaminghi/data-view";
import Print from "vue-print-nb";

import "../public/style/iconfont.css";

Vue.config.productionTip = false;


Vue.use(util);

Vue.use(dataV);
Vue.use(Print);
Vue.prototype.$bus = new Vue();

import {
	ColorPicker,
	Autocomplete,
	Container,
	Aside,
	Main,
	Descriptions,
	DescriptionsItem,
	Skeleton,
	SkeletonItem,
	Steps,
	Step,
	Progress,
	Collapse,
	CollapseItem,
	Empty,
	Menu,
	MenuItem,
	Submenu,
	Card,
	Row,
	Col,
	Pagination,
	Dialog,   // 对话框
	Dropdown, // 下拉菜单
	DropdownMenu,
	DropdownItem,
	Input,
	InputNumber,
	Radio,
	RadioGroup,
	RadioButton,
	Checkbox,
	CheckboxButton,
	CheckboxGroup,
	Switch,
	Select,
	Option,
	OptionGroup,
	Button,
	ButtonGroup,
	Table,
	TableColumn,
	DatePicker,
	TimeSelect,
	TimePicker,
	Popover,
	Tooltip,
	Form,
	FormItem,
	Tabs,
	TabPane,
	Tag,
	Tree,
	Alert,
	Slider,
	Icon,
	Image,
	Upload,
	Badge,
	Rate,
	Cascader,
	Timeline,
	TimelineItem,
	Link,
	Divider,
	CascaderPanel,
	Loading,
	MessageBox,
	Message,
	Notification
} from 'element-ui';

Vue.use(ColorPicker);
Vue.use(Autocomplete);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Progress);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Empty);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Card);
Vue.use(Col);
Vue.use(Row);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(Badge);
Vue.use(Rate);
Vue.use(Cascader);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(CascaderPanel);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;



Vue.prototype.$getAreaText = locat => {
	if (locat === null) return "无";
	const locationArray = locat.split("/");
	if (locationArray.length === 0) {
		return "无";
	}
	const areaDataList = areaData.districts[0].districts;
	const areaTemp = [];
	function getFullData (list) {
		if (list.length === 0) return 0;
		for (let item of list) {
			if (item.adcode === locationArray[areaTemp.length]) {
				areaTemp.push({
					adcode: item.adcode,
					name: item.name
				});
				getFullData(item.districts);
				break;
			}
		}
	}
	getFullData(areaDataList);
	return areaTemp.map(item => item.name).join("/");
};
//   1: "管理员",
//   2: "医生",
//   3: "药房",
//   4: "客人",
//   5: "厂商",
//   6: "调理师",
//   7: "客服",
//   8: "煎药房",
//   9: "库房",
//   10: "数据管理员",
//   11: "门诊",
//   12: "公司",
//   13: "门诊医助",
//   14: "财务",
//   15: "系统管理"

Vue.use(TinMain, {
	store,
	components: {
		Login
	},
	router: config
});
