<template>
	<div class="tin-lview">
		<!-- <ul class="menu">
      <li @click="onClick(item)"
          class="sidebar-menu__list-item"
          :class="{ active: item.name ===$route.name }"
          v-for="(item, index) in lViewMenu"
          :key="index">
        {{ item.meta.title }}
      </li>
    </ul> -->
		<div class="menu">
			<el-menu :default-active="activeIndex" ref="meuns" class="el-menu-demo" @select="handleSelect">
				<template v-for="(item, index) in lViewMenu">
					<el-menu-item v-if="!item.children" class="el-menu-class" :key="index" :index="index + ''">
						<!-- <i class="el-icon-menu"></i> -->
						<span class="tu-icon icon-style">&#xeb99;</span>
						<b> {{ item.meta.title }}</b>
					</el-menu-item>
					<el-submenu :index="index + ''" v-else :key="index + ''">
						<template slot="title">
							<span class="tu-icon" style="font-weight: bold;font-size: 12px; padding-right: 10px;">&#xeb99;</span>
							<b>{{ item.meta.title }}</b>
						</template>
						<el-menu-item :index="index + '-' + indexa" v-for="(itema, indexa) in item.children" :key="indexa">
							<template slot="title">
								<span style="margin-left:-16px;"> <span class="tu-icon icon-sec">&#xec1e;</span>
									<span style=""> {{ itema.meta.title }}</span></span>
							</template>
						</el-menu-item>
					</el-submenu>
				</template>
			</el-menu>
		</div>

	</div>
</template>

<script>
export default {
	name: "LView",

	props: {
		lViewMenu: Array,
		active: String,
		currentName: String
	},
	data () {
		return {
			// activeIndex: "0"
		}
	},

	computed: {
		activeIndex () {
			// this.init()
			return this.$store.state.app.active
		}
	},
	watch: {
		currentName: {
			handler (newVal, oldVal) {
				// console.log("newVal oldVal", newVal, oldVal)
				if (newVal) {
					// console.log("val change")
					this.init()
				}
			}
		}

	},


	methods: {
		onClick (eleData) {
			this.$router.push({ name: eleData.name, parent: { id: this.id } });
		},

		handleSelect (e) {
			// console.log("e", e)
			if (e === this.activeIndex) {
				// this.$router.go(0)
				// this.reload();
				this.$store.commit("changeAct", e)
				// this.$store.commit("changeAct", e)
			} else {
				// this.activeIndex = e
				// this.$store.commit("changeAct", e)
				let valArr = e.split("-")
				let listArr = this.lViewMenu
				if (valArr.length === 1) {
					// console.log("length===1")
					let rPath = listArr[valArr[0]]
					this.$store.commit("changeAct", e)
					this.$router.push({ name: rPath.name, parent: { id: this.id } });

				} else {
					// console.log("length===2")
					let rPath = listArr[valArr[0]].children[valArr[1]]
					// console.log('rPath', rPath)
					this.$store.commit("changeAct", e)
					this.$router.push({ name: rPath.name, parent: { id: this.id } });

				}
			}


		},
		init () {

			// this.activeIndex = this.$store.state.app.active

			// // console.log("this.active", this.active)
			// console.log("this.meuns", this.$refs.meuns)
			if (this.$refs.meuns) {
				console.log('Left init')
				this.$refs.meuns.activeIndex = this.$store.state.app.active
			}
		},

	},
	created () {

		this.init()

	},

};
</script>

<style scoped>
.tin-lview {
	width: 100%;
	/* height: 50; */
	user-select: none;
}

.icon-style {
	font-size: 12px;
	font-weight: bold;
	padding-right: 5px;
}

.icon-sec {
	font-size: 12px;
	font-weight: bold;
	padding-right: 5px;
	color: #909399;
}

.menu {
	position: absolute;
	top: 0px;
	width: 100%;
	bottom: 0;
	padding: 0;
	font-size: 14px;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
}

.el-menu-class {
	height: 40px;
	line-height: 40px;
}

.el-menu /deep/ .el-submenu__title {
	height: 40px !important;
	line-height: 40px !important;
}

.el-menu-item /deep/ .el-submenu__title {
	line-height: 40px !important;
}

.el-menu-item {}

.el-submenu .el-menu-item {
	height: 40px !important;
	line-height: 40px !important;
}

/* ul {
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  align-items: center;
  padding: 0 20px;
  user-select: none;
  cursor: pointer;
  height: 30px;
  line-height: 1;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
li:hover {
  background-color: #f2f2f2;
}

.menu > li::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: transparent;
  border: 2px solid #888;
  border-radius: 50%;
  margin-right: 10px;
}

li.active {
  background-color: #e4e4e4;
}

.menu > li::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  transform: rotate(45deg);
  position: absolute;
  right: 20px;
}

.tin-li-child {
  background-color: #f5f5f5;
}

.tin-li-child li {
  height: 30px;
  padding-left: 35px;
}

.tin-li-child li.active {
  background-color: #ccc;
  color: #fff;
} */
</style>


