<template>
	<div class="home">
		<!-- <img src="/newLogo.png" alt /> -->
		<div class="title">欢迎使用秉华数据医疗服务平台</div>
		<br />
		<br />
		<div class="expired" v-if="false">
		</div>

		<el-dialog title="提示" :visible.sync="test" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="test = false">取 消</el-button>
				<el-button type="primary" @click="test = false">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="" :visible.sync="dialogVisible" top="10vh" width="55%" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false">
			<div class="dialogContent">
				<p style=";margin-bottom:10px;text-align:center;line-height:150%;background:white">
					<strong><span
							style="font-size:29px;line-height: 150%;font-family:宋体;color:#333333;background:white">&nbsp;免责声明</span></strong>
				</p>
				<p
					style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left: 0;text-indent:28px;line-height:150%;background: white">
					<span
						style="font-family:宋体;color:black">凡以任何方式登录“秉华健康”平台，请您务必仔细阅读并透彻理解本声明，如果您使用“秉华健康”平台，您的使用行为将被视为对本声明全部内容的认可。</span>
				</p>
				<p
					style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left: 0;text-indent:28px;line-height:150%;background: white">
					<span
						style="font-family:宋体;color:black">一、“秉华健康”平台如因不可抗力或下列状况无法正常运作，导致网上交易无法完成或丢失有关的信息、记录等，网站将不承担责任。但是我们将会尽合理的可能协助处理善后事宜，并努力使客户减少可能遭受的经济损失。该状况包括但不限于：</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">云平台系统停机升级或维护期间。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">2) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">电信设备出现故障不能进行数据传输的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">3) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">基础云平台服务提供商故障导致的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">4) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">5) </span><span
						style="font-size:16px;line-height:150%;font-family:宋体">由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">6)</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">网络状况、通讯线路、第三方网站及其造成的各种损失（包括因下载被搜索链接到的第三方网站内容而感染电脑病毒）</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">二、“秉华健康”平台提供的各种诊断测验均为本站成员原创或整理，版权归作者与本站共同所有。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">若作者有版权声明的或文章从其他网站转载而附带有原所有站的版权声明者，其版权归属以附带声明为准。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">三、“秉华健康”平台所提供的各种信息不被视为医生诊断处方权，并做如下规定：</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">、“秉华健康”平台仅作为医生诊疗过程中的辅助参考，一切诊断与治疗均为医生的行为。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">2</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">、如医生利用“秉华健康”平台出现医疗纠纷，医生承担全部责任，与“秉华健康”平台无关。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">四、“秉华健康”平台有与其它网站的链接，所链接的网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，“秉华健康”平台对其合法性概不负责，亦不承担任何法律责任。“秉华健康”平台在收到上述法律文件后，将会依法尽快断开相关链接内容或在三个工作日内修改或删除转载相关内容。详情参见特定频道的著作权保护声明。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">五、除“秉华健康”平台注明的服务条款外，秉华云健康平台不做以下任何形式的保证：不保证所有辅助诊疗、用药、查询结果满足使用者的需求；不保证搜索结果的安全性、正确性、及时性、合法性；不保证搜索服务不中断。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">六、用户发布内容时，即表示该用户许可本公司所属公司及关联公司、复制、展览、演示、传播、翻译、编辑和创作派生等信息，本公司尊重该用户作为作者对内容拥有分享产权，作者有权在任何时间要求本公司在我们的应用及/或网站中将其删除，本公司承诺始终对个人信息进行保密。</span>
				</p>
				<p style="margin-left:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">七、用户不得发布或上传含有以下内容的信息：</span>
				</p>
				<p style="margin-left:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family:宋体">1</span><span
						style="font-size:16px;line-height:150%;font-family:宋体">）欺诈、违法或误导信息；<br />
						2）侵犯他人分享产权、著作权或隐私的信息；</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">鼓励用户将网站中不合时宜或非法内容通知本站，我们将保留无理由删除任何发布内容的权利。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span
						style="font-size:16px;line-height:150%;font-family: 宋体">八、“秉华健康”平台在线问答系统是为网友提供的服务性公共性交流平台，回复者为中医专家和医生，亦有本网在线工作人员。回答问题仅针对提问者描述的证状提供建议性内容，不能作为诊疗的依据。</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">本声明及其修改权、更新权、最终解释权均属江苏秉华健康科技有限公司所有。&nbsp;</span>
				</p>
				<p style="text-indent:32px;line-height:150%">
					<span style="font-size:16px;line-height:150%;font-family: 宋体">感谢您对本条款的阅读，“秉华健康”平台衷心祝您健康、平安！</span>
				</p>
				<p style="line-height: 150%;">
					<span style="font-size: 16px;line-height:150%;font-family:宋体">&nbsp;</span>
				</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="hasReadAgree">我已阅读并同意</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { formatDate } from "../../util/date.js";
import axios from "axios";

export default {
	title: "Home",
	data () {
		return {
			hasRead: false,
			dialogVisible: false,
			account: "",
			day: null,
			test: false,
			img: ''
		};
	},
	methods: {
		hasReadAgree () {
			this.$put("/bh/jk/account/agree").then(() => {
				this.dialogVisible = false;
				localStorage.setItem("isAgree", true);
			});
		}

	},
	created () {
		let a = [
			['正常', '大便干结', '排便困难', '大便三天一次', '大便五天一次', '大便七天一次'],
			['正常 ', '黄褐臭秽', '清稀水样', '大便一天三次', '大便一天五次', '大便一天七次'],
			['正常  ', '略稀', '糊状', '时干时稀', '先干后稀', '排便不尽感']
		]

		let b = ['正常', '大便三天一次', '黄褐臭秽', '正常  ']

		// let resultIndicesArray = [];

		// for (let rowIndex = 0; rowIndex < a.length; rowIndex++) {
		// 	let resultIndicesRow = [];
		// 	for (let colIndex = 0; colIndex < a[rowIndex].length; colIndex++) {
		// 		let indexInB = b.indexOf(a[rowIndex][colIndex]);
		// 		resultIndicesRow.push(indexInB !== -1 ? 1 : 0);
		// 	}
		// 	resultIndicesArray.push(resultIndicesRow);
		// }

		// console.log(resultIndicesArray);
		// axios.get("/bh/project/bhDiseaseGoods/getGoodsListByDiseaseId?diseaseId=1527330679816256")
		// this.$get('/prod-api/project/bhDiseaseGoods/getGoodsListByDiseaseId?diseaseId=1527330679816256')
		let isAgree = localStorage.getItem("isAgree") === "true" ? true : false;
		this.dialogVisible = !isAgree;


	},
	mounted () {
		// this.getLine();
	},

	// beforeRouteLeave(to, from, next) {
	//   console.log("1111")
	//   this.hidden = true
	//   // this.$destroy();
	//   next();
	// },

	computed: {

		isExpired () {
			// return this.expiredDate != null;
		}
	},
	filters: {
		formatDate (time) {
			var date = new Date(time);
			return formatDate(date, "yyyy年MM月dd日");
		}
	}
};
</script>

<style lang="scss" scoped>
.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

img {
	width: 100px;
	height: 100px;
}

.title {
	font-size: 18px;
	color: #1d9244;
	margin-top: 10px;
}

.expired {
	font-size: 22px;
	color: #db0000d0;
	margin-top: 10px;
}

.dialogContent {
	height: 600px;
	overflow: auto;
}

.container {
	height: 500px;
	width: 500px;
	position: relative;
	border: 1px solid red;

	.box {
		text-align: center;
		position: absolute;
		display: inline-block;
		height: 80px;
		width: 80px;
		border-radius: 50%;
		border: 1px solid rgb(28, 134, 204);
		// z-index: 99;
		// background-color: red;
	}

	.tongue {
		left: 42%;
		top: 0;
	}

	.lungs {
		right: 0;
		top: 20%;
	}

	.heart {
		left: 0;
		top: 20%;
	}

	.liver {
		left: 0;
		top: 60%;
	}

	.spleen {
		right: 0;
		top: 60%;
	}

	.stomach {
		left: 42%;
		top: 60%;
	}

	.kindey {
		left: 42%;
		bottom: 0;
	}
}

.box-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.title-font-size {
	font-size: 14px;
}

.big-size {
	font-size: 50px;
}

.isRed {
	// color: red;
}

.tttt {
	width: 800px;
	height: 800px;
	border: 1px solid red;

}
</style>
