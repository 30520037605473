<template>
  <div class="t-area">
    <el-cascader size="small"
                 :value="location"
                 :options="options"
                 @change="bindChange"
                 placeholder="请输入城市名称"
                 filterable></el-cascader>
    <!-- <el-cascader v-model="value"
                 :options="options"
                 @change="handleChange"></el-cascader> -->
  </div>
</template>

<script>
import areaData from "./data";

export default {
  name: "t-area",
  data () {
    return {
      options: [],
      ttttt: false
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 0 //0需要详细区县 
    }
  },
  computed: {
    location () {
      if (!this.value) {
        return [];
      } else if (isNaN(parseInt(this.value.split("/")[0]))) {
        return [];
      } else {
        return this.value.split("/");
      }
    }
  },
  methods: {
    getAreaList (list) {
      if (list.length === 0) return 0;
      return list.map(item => {
        return {
          value: item.adcode,
          label: item.name,
          children: item.districts.map(items => {
            return {
              value: items.adcode,
              label: items.name
            }
          })
        };
      });
    },
    getAllAreaList (list) {
      if (list.length === 0) return 0;
      return list.map(item => {
        return {
          value: item.adcode,
          label: item.name,
          children: this.getAllAreaList(item.districts)
        };
      });
    },
    bindChange (data) {
      const areaDataList = areaData.districts[0].districts;
      const areaTemp = [];
      function getFullData (list) {
        if (list.length === 0) return 0;
        for (let item of list) {
          if (item.adcode === data[areaTemp.length]) {
            areaTemp.push({
              adcode: item.adcode,
              name: item.name
            });
            getFullData(item.districts);
            break;
          }
        }
      }
      getFullData(areaDataList);
      this.$emit("change", areaTemp);
      this.$emit("input", areaTemp.map(item => item.adcode).join("/"));
    }
  },

  mounted () {
    console.log("type", this.type)
    if (this.type === 1) {
      this.options = this.getAreaList(areaData.districts[0].districts);
    } else {
      this.options = this.getAllAreaList(areaData.districts[0].districts);
    }

    // console.log("123",areaData.districts[0].districts)
    // let a = areaData.districts[0].districts.map(item=>{
    // })
  }
};
</script>

<style scoped>
.t-area {
  width: 100%;
}
</style>
<style>
.el-cascader-menu {
  height: 300px;
}
</style>
