
import crudData from "@/components/crud-data";
import tChart from "@/components/t-chart";
import area from "@/components/area/area";
import pageView from "@/components/layout/page-view";
import compatibility from "@/components/compatibility.vue";
import DateRange from "@/components/DateRange.vue";
import dayjs from "dayjs";
import { Button } from "element-ui";
import areaData from "@/components/area/data.json";

export default {
	install (Vue) {
		// 1. 添加全局方法或属性
		// Vue.myGlobalMethod = function () {
		//   // 逻辑...
		// }
		// // 2. 添加全局资源
		// Vue.directive('my-directive', {
		//   bind (el, binding, vnode, oldVnode) {
		//     // 逻辑...
		//   }
		// })
		// 3. 注入组件
		// Vue.mixin({
		//   created: function () {
		//     // 逻辑...
		//   }
		// })
		// // 4. 添加实例方法
		// Vue.component('money', {
		//   template: '<div>￥<slot></slot></div>'
		//  })
		// Vue.component("money", money);
		Vue.component("crud-data", crudData);
		Vue.component("t-chart", tChart);
		Vue.component("t-area", area);
		Vue.component("t-button", Button);
		Vue.component("t-compatibility", compatibility);
		Vue.component("page-view", pageView);
		Vue.component("data-range", DateRange);

		Vue.mixin({
			created () {
				if (this.pageTitle) {
					document.title = this.pageTitle;
				}
			},
			methods: {
				toPage (url, replace = false) {
					if (replace) {
						this.$router.replace(url);
					} else {
						this.$router.push(url);
					}
				}
			}
		});

		// Vue.prototype.$utils = {
		//     tailor,
		//     setData,
		//     getData,
		//     removeData,
		//     setDataLocal,
		//     getDataLocal
		// };

		Vue.prototype.$dayjs = dayjs;
		Vue.prototype.$getBirthdayFromIdCard = idCard => {
			let birthday = "";
			if (idCard != null && idCard != "") {
				if (idCard.length == 15) {
					birthday = "19" + idCard.substr(6, 6);
				} else if (idCard.length == 18) {
					birthday = idCard.substr(6, 8);
				}

				birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
			}
			return birthday;
		};

		Vue.prototype.$parseCard = cardNum => {
			const birthday =
				cardNum.substr(6, 4) +
				"-" +
				cardNum.substr(10, 2) +
				"-" +
				cardNum.substr(12, 2);
			const sex = cardNum.substr(16, 1) % 2 ? 1 : 2;
			return {
				birthday,
				sex
			};
		};

		Vue.prototype.$percentage = data => {
			return ((Number(data) * 10000) / 100).toFixed(2) + "%";
		};

		Vue.prototype.$getAreaText = location => {
			const locationArray = location.split("/");
			const areaDataList = areaData.districts[0].districts;
			const areaTemp = [];
			function getFullData (list) {
				if (list.length === 0) return 0;
				for (let item of list) {
					if (item.adcode === locationArray[areaTemp.length]) {
						areaTemp.push({
							adcode: item.adcode,
							name: item.name
						});
						getFullData(item.districts);
						break;
					}
				}
			}
			getFullData(areaDataList);
			return areaTemp.map(item => item.name).join("/");
		};

		Vue.prototype.$symptomCategory = data => {
			switch (data) {
				case 1:
					return "头面部";
				case 2:
					return "上焦";
				case 3:
					return "中焦";
				case 4:
					return "下焦";
				case 5:
					return "全身";
				default:
					break;
			}
		};

		Vue.filter("percentage", data => {
			return ((Number(data) * 10000) / 100).toFixed(2) + "%";
		});
	}
};
