import {
	SET_SECONDARY_MENU,
	SET_USER,
	SET_TOKEN,
	SET_LOADING,
	SET_APP_MENU
} from "@/store/mutation-types";
import { get } from "@/util/http";
import config from "@/config.js";

const state = {
	loading: true,
	user: null,
	appMenu: [],
	token: "",
	active: "0"
};

// getters
const getters = {
	appMenu (state) {
		return state.appMenu;
	},
	user (state) {
		return state.user;
	},
	loading (state) {
		return state.loading;
	}
};

// actions
const actions = {
	getUserInfo (context, data) {
		return get("/bh/jk/account/userinfo").then(res => {
			context.commit(SET_LOADING, false);
			if (res.code === 20000 || res.code == 200) {
				context.commit(SET_USER, {
					status: res.data.status,
					name: res.data.realname,
					id: res.data.userId,
					account: res.data.username,
					cardId: res.data.cardid,
					mobile: res.data.mobile,
					sex: res.data.gender,
					type: res.data.usertypeid
				});
				// context.commit(SET_APP_MENU, data);
			}
			return res;
		});
	},
	loginOut () {
		localStorage.removeItem("auth");
		location.assign("/");
	}
};

// mutations
const mutations = {
	[SET_USER] (state, { status, name, id, account, cardId, mobile, type, sex }) {
		state.user = { status, name, id, account, cardId, mobile, type, sex };
	},
	[SET_TOKEN] (state, data) {
		state.token = data;
	},
	[SET_LOADING] (state, data) {
		state.loading = data;
	},

	[SET_APP_MENU] (state, data) {
		const tempMenu = config.routes[0].children.map((item, index) => {
			let tempItem = null;
			switch (state.user.type) {
				case 2:
					if (item.path === "doctor") {
						tempItem = {
							...item,
							children: [item.children[1]]
						};
					} else {
						tempItem = item;
					}
					break;
				default:
					tempItem = item;
					break;
			}
			return {
				...tempItem,
				active: data.path.indexOf("/" + item.path) === 0
			};
		});

		switch (state.user.type) {
			case 1:
				state.appMenu = tempMenu.filter(item => {
					return [
						"dashboard",
						"patient",
						"doctor",
						"outpatient",
						"research",
						"basedata",
						"update"
					].includes(item.path);
				});
				break;
			case 10:
				state.appMenu = tempMenu.filter(item => {
					return [
						"dashboard",
						"patient",
						"doctor",
						"outpatient",
						"research",
						"basedata",
						"smartdata",
						"update"
					].includes(item.path);
				});
				break;
			case 2:
				state.appMenu = tempMenu.filter(item => {
					return ["dashboard", "patient", "doctor", "update"].includes(item.path);
				});
				break;
			case 6:
				state.appMenu = tempMenu.filter(item => {
					return ["dashboard", "patient", "doctor", "update"].includes(item.path);
				});
				break;
			case 5:
				state.appMenu = tempMenu.filter(item => {
					return [
						"dashboard",
						"patient",
						"doctor",
						"outpatient",
						"basedata",
						"smartdata",
						"update",
					].includes(item.path);
				});
				break;

			case 8:
				state.appMenu = tempMenu.filter(item => {
					return ["dashboard", "pharmacy"].includes(item.path);
				});
				break;
			default:
				state.appMenu = tempMenu.filter(item => {
					return ["dashboard"].includes(item.path);
				});
				break;
		}
	},

	changeAct (state, val) {
		state.active = val
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
