<template>
  <div class="chart" :id="id"></div>
</template>

<script>
import G2 from "@antv/g2";
export default {
  name: "t-chart",
  props: {
    id: {
      type: String,
      default: "chart"
    },
    data: {
      type: Object,
      default() {
        return {
          data: [],
          defs: {},
          type: null,
          line: null
        };
      }
    },
    defs: Object
  },
  data() {
    return {
      chart: null
    };
  },
  methods: {
    render(data, defs, type, line) {
      this.chart.source(data, defs);
      this.chart.legend({
        position: "top", // 设置图例的显示位置
        itemGap: 30, // 图例项之间的间距
        attachLast: true
      });
      this.chart
        .line()
        .position("x*y")
        .color("type", type);
      this.chart
        .point()
        .position("x*y")
        .color("type", type)
        .size(4)
        .shape("circle")
        .style({
          stroke: "#fff",
          lineWidth: 1
        });
      // if (line) {
      //   this.chart.guide().line(line);
      // }
      this.chart.axis("x", {
        line: {
          lineWidth: 2, // 设置线的宽度
          stroke: "#888888" // 设置线的颜色
          // lineDash: [3, 3] // 设置虚线样式
        }
      });
      this.chart.axis("y", {
        grid: {
          type: "line",
          lineStyle: {
            stroke: "#d9d9d9",
            lineWidth: 1
          },
          align: "start" // 网格顶点从两个刻度中间开始
        },
        line: {
          lineWidth: 2, // 设置线的宽度
          stroke: "#888888" // 设置线的颜色
          // lineDash: [3, 3] // 设置虚线样式
        }
      });
      if (line) {
        this.chart.axis("x", {
          line: {
            lineWidth: 2, // 设置线的宽度
            stroke: "#eeeeee" // 设置线的颜色
            // lineDash: [3, 3] // 设置虚线样式
          }
        });
      }

      if (line) {
        this.chart.guide().html({
          position: ["min", line.text.y],
          html: `
                  <div style="width: 550px; border-bottom: 2px solid #888888; font-size: 12px; color: #888; text-align:right ">
                    <span>${line.text.content}</span>
                  </div>
                `,
          alignX: "left",
          alignY: "bottom",
          offsetX: 0,
          offsetY: 3
        });
      }

      this.chart.render();
    }
  },

  mounted() {
    this.chart = new G2.Chart({
      container: this.id,
      width: 800,
      height: 300,
      padding: [40, 200, 70, 70]
    });
    // this.render(this.data.data, this.data.defs, this.data.type);
  },
  watch: {
    data() {
      this.render(
        this.data.data,
        this.data.defs,
        this.data.type,
        this.data.line
      );
    }
  }
};
</script>

<style scoped>
.chart {
  padding-top: 20px;
}
</style>
