import Tin from "./Tin";
import Router from "vue-router";
import App from "./App";
import { get, post, del, put, postImg, getQrCode, postForms } from "@/util/http";
const install = function (Vue, config) {
	let app;
	Vue.component(Tin.name, Tin);
	Vue.component(config.components.Login.name, config.components.Login);

	Vue.use(Router);

	Vue.prototype.$get = get;
	Vue.prototype.$postImg = postImg;
	Vue.prototype.$post = post;
	Vue.prototype.$postForms = postForms;
	Vue.prototype.$put = put;
	Vue.prototype.$getQrcode = getQrCode;

	Vue.prototype.$del = del;



	Vue.prototype.getUserInfo = next => app.$root.getUserInfoC(next);

	const userPermission = JSON.parse(
		localStorage.getItem("user-permission") || "[]"
	);
	const userPermissionList = userPermission.map(item => item.code);

	Vue.prototype.$has = (code = 0) => userPermissionList.includes(String(code));

	const pages = config.router.pages
		.map(item => {
			return {
				...item,
				component:
					"children" in item && !("component" in item)
						? { render: c => c("router-view") }
						: item.component,
				children: item.children
					.map(itemb => {
						return {
							...itemb,
							component:
								"children" in itemb && !("component" in itemb)
									? { render: c => c("router-view") }
									: itemb.component
						};
					})
					.filter(item => {
						return userPermissionList.includes(String(item.meta.code));
					})
			};
		})
		.filter(item => {
			return userPermissionList.includes(String(item.meta.code));
		});

	const comPages = config.router.comPages.map(item => {
		return {
			...item,
			com: true
		};
	});

	const router = new Router({
		routes: [...comPages, ...pages]
	});

	// router.push({name: "login"})

	// router.addRoutes([])

	const authorization = localStorage.getItem("auth");

	router.beforeEach(async (to, from, next) => {
		const check = await get("/bh/jk/account/check");
		// 4001, "用户未登录，请先登录"
		// 4002, "登录过期，请重新登录"
		// 4005, "账户异地登录"
		if ([4001, 4002, 4005].includes(check.code)) {
			app.$root.isLogin = false;
		} else {
			app.$root.isLogin = true;
		}
		app.$root.init = false;

		// if (isLogin !== false) {

		// }
		next();
		// if (authorization) {
		// 	next();
		// } else {
		// 	// config.store.commit("SET_LOADING", false);
		// }
	});

	app = new Vue({
		router,
		store: config.store,
		data () {
			return {
				menu: [...pages, ...comPages],
				init: true,
				isLogin: false,
				userInfo: null,
				userPermissionList
			};
		},
		methods: {
			getUserInfo () {
				return get("/bh/jk/account/userinfo").then(res => {
					if (res.code === 200) {
						this.userInfo = res.data
					}
				});
			},
			getUserInfoC (next) {
				if (this.userInfo === null) {
					let temp = setTimeout(() => {
						if (this.userInfo !== null) {
							next(this.userInfo);
							clearTimeout(temp);
						}
					}, 100);
				} else {
					next(this.userInfo);
				}
			}
		},
		created () {
			this.getUserInfo();
		},
		render: h => h(App)
	}).$mount("#app");
};

if (typeof window !== "undefined" && window.Vue) {
	install(window.Vue);
}
const router = {};
export { Router, router, get, post, del, put };
export default install;
