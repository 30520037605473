<template>
  <el-date-picker
    class="select-date"
    style="width: 260px;"
    size="small"
    :value="value"
    @input="e => $emit('input', e)"
    @change="onChange"
    :type="type"
    :clearable="false"
    :editable="false"
    range-separator="~"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    placeholder="开药开始时间"
    :value-format="valueFormat"
    :unlink-panels="true"
  ></el-date-picker>
</template>

<script>
export default {
  title: "DataRange",
  props: {
    value: Array,
    type: {
      type: String,
      default: "daterange"
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd"
    }
  },
  data() {
    return {};
  },
  methods: {
    changeStartDate(e) {
      if (new Date(e) > new Date(this.value[1])) {
        this.$message.error("开始时间不能大于结束时间");
        return 1;
      }
      this.$emit("input", [e, this.value[1]]);
      this.change([e, this.value[1]]);
    },
    changeEndDate(e) {
      if (new Date(this.value[0]) > new Date(e)) {
        this.$message.error("结束时间不能小于开始时间");
        return 1;
      }
      this.$emit("input", [this.value[0], e]);
      this.change([this.value[0], e]);
    },
    // function
    onChange(e) {
      console.log(e);
      this.$emit("change", e);
    }
  }
};
</script>

<style scoped>
.date-range {
  display: inline-block;
}

.select-date {
  margin: 0 !important;
}
</style>